@use "~@lysaab/ui-2/components/styling/media";

$combined-table-button-padding: calc(26px - var(--lysa-button-border-size));

.germany-tax-details {
  .germany-tax-details__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    align-items: flex-end;

    .germany-tax-details__header-details {
      max-width: 400px;
      width: 100%;

      dl {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        dt {
          width: 60%;
        }
        dd {
          margin-left: 0;
          width: 40%;
          text-align: right;
        }
      }
    }

    .germany-tax-details__header-button {
      margin-bottom: 2rem;
    }
  }

  .germany-tax-details__print-break-before {
    break-before: page;
  }

  .germany-tax-details__table-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    margin-bottom: 20px;

    .germany-tax-details__table {
      .germany-tax-details__table--space-left {
        @include media.desktop {
          padding-left: 10px;
        }
      }

      @include media.desktop {
        th:first-child,
        td:first-child {
          text-align: left;
        }
        th:last-child {
          text-align: right;
          padding-right: $combined-table-button-padding;
        }
        td:last-child {
          text-align: right;
        }

        .germany-tax-details__table--text-right {
          text-align: right;
        }
      }
    }
  }

  .germany-tax-details__divider {
    margin: 10px 0;
  }

  .germany-tax-details__hide-in-print {
    @media print {
      display: none;
    }
  }
}
