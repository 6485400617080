.pension-move-card {
  background-color: var(--color-gray-50);
  padding: 2rem;
  border-radius: 1rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .company {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.4rem;
  }

  .name-and-worth {
    display: flex;
    flex-direction: column;
  }

  .institute {
    font-weight: 700;
    font-size: medium;
  }

  .information-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .information {
    display: flex;
    align-items: flex-start;
    gap: 1.6rem;
  }

  .information-from-type-content {
    display: flex;
    flex-direction: column;
  }

  .information-message {
    margin: 0;
  }

  .information-message-header {
    margin: 0;
    font-weight: bold;
  }

  hr {
    height: 1px;
    background-color: black;
    opacity: 0.15;
    border: none;
    margin: 1.6rem 0 1.2rem 0;
  }

  dl {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    dd {
      font-weight: bold;
      text-align: end;
    }
  }

  dl:last-of-type {
    margin-bottom: 0;
  }

  /* The Simon rule. Make sure it looks nice on really small phones as well. */

  @media (max-width: 390px) {
    dl {
      dt {
        margin-right: 1rem;
      }

      dd {
        margin-inline-start: 0;
      }
    }
  }
}
