@use "~@lysaab/ui-2/components/styling/variables";

.account-information-help {
  font-size: 12px;

  svg {
    margin-bottom: -3px;
    margin-right: 6px;
  }
}
