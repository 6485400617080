.transfer-pension-intro-short {
  .title-image {
    height: 140px;
  }

  padding-bottom: 16px;
  .typography--display-small {
    font-size: 24px;
  }

  .ingress {
    font-size: 14px;
    padding: 0 16px;
  }

  .exit-button {
    margin-top: 6rem;
  }
}

.transfer-pension-intro-actions {
  background-color: var(--lysa-background-screen);
}
