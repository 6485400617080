:root {
  --lysa-cmd-k-box-shadow: 0 6px 20px rgba(0 0 0 / 20%);
  --lysa-cmd-k-icon-color: var(--color-black);
  --lysa-cmd-k-active-item-background: var(--color-gray-50);
}

[data-theme="dark"] {
  --lysa-cmd-k-box-shadow: 0 6px 20px rgba(255 255 255 / 20%);
  --lysa-cmd-k-icon-color: var(--color-white);
  --lysa-cmd-k-active-item-background: var(--color-gray-900);
}

.cmd-k-result-item {
  padding: 12px 16px;
  background: transparent;
  border-left: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.active-item {
    background: var(--lysa-cmd-k-active-item-background);
  }

  .icon-ancestors-name-action {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14;

    .icon-primary {
      fill: var(--lysa-cmd-k-icon-color);
    }

    .ancestors-name {
      display: flex;
      flex-direction: column;
    }

    .ancestor-name {
      opacity: 0.5;
      margin-right: 8px;
    }

    .ancestor-rsaquo {
      margin-right: 8px;
    }

    .action-subtitle {
      font-size: 12;
      color: var(--lysa-text-secondary);
    }
  }

  .shortcut-keys {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;

    kbd {
      padding: 4px 6px;
      background: var(--color-gray-200);
      border-radius: 4px;
      font-size: 14;
    }
  }
}
