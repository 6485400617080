@use "~@lysaab/ui-2/components/styling/media";

.share-account-invitations-page {
  .col-button {
    margin-top: 0px;
  }

  .col-button ~ .col-button {
    margin-top: 12px;
  }

  @include media.tablet {
    .col-button {
      margin-top: 12px !important;
    }
  }
}
