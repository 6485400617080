.tax-residence-list {
  &__country {
    margin-bottom: 18px;

    .lysa-ui-input-group {
      margin-bottom: 24px;
    }
    .lysa-ui-input-group + .lysa-ui-input-group {
      margin-bottom: 6px;
    }

    .lysa-ui-button.button-secondary.button-circle {
      display: inline-flex;
    }
  }
}
