.styled-percent {
  font-weight: 500;

  .percent-sign {
    opacity: 0.7;
    margin: 0;
    font-weight: normal;
    font-size: 0.8em;
  }
}
