@use "~@lysaab/ui-2/components/styling/variables";

.account-page-transactions {
  .internal-transfer-entry {
    display: flex;
    flex-wrap: nowrap;
    padding: 12px 0 0 0;

    &.last-entry {
      position: absolute;
      width: 100%;
    }

    & > div {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }

    .internal-transfer-entry-indicator {
      display: inline-block;
      margin: 0 4px 0 0;
      border-radius: 2px;
      width: 10px;
      height: 10px;
      vertical-align: middle;
    }

    &.internal-transfer-entry-deposit,
    &.internal-transfer-entry-withdrawal {
      .internal-transfer-entry-indicator {
        background-color: var(--lysa-indicator-internal);
      }
    }
  }
}
