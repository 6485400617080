@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.performance-graph-wrapper {
  position: relative;

  .performance-graph-info {
    transition: opacity 300ms ease;
    opacity: 1;

    @include media.desktop {
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
    }
  }

  .retry-wrapper {
    .lysa-ui-spinner {
      opacity: 0.4;
    }
  }

  .animated-event-element {
    height: 22px;
    transition: height 300ms ease;
    overflow: hidden;

    @include media.tablet {
      height: 28px;
    }
  }

  &.show-animated-event-element {
    .animated-event-element {
      height: 47px;

      @include media.tablet {
        height: 60px;
      }

      // @include media.desktop {
      //   height: 50px;
      // }
    }
  }

  .performance-graph {
    height: 0;
    padding: 0 0 90% 0;
    margin: 0 28px 0 8px;

    @include media.landscape {
      padding-bottom: 45%;
    }

    @include media.tablet {
      padding-bottom: 30%;
      margin: 0 44px 0 24px;
    }

    @include media.desktop {
      padding-bottom: 20%;
    }

    @include media.wide {
      padding-bottom: 25%;
    }
  }
}
