.isk-to-savings-done-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .done-page__check-icon {
    border: 4px solid var(--lysa-icon-secondary);
    border-radius: 30px;
    .icon-primary {
      fill: var(--lysa-icon-secondary);
    }
  }

  .done-page-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    .row {
      display: flex;
      flex-direction: column;
      align-items: center;

      .typography:first-child {
        color: var(--lysa-text-secondary);
      }
    }
    .horizontal-divider-wrapper {
      margin-top: 0;
    }
  }

  .account-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .lysa-ui-button {
    margin-top: 24px;
  }
}
