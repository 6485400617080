@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

$background-position: 90% 35%;

.create-account-intro {
  .create-account-intro__ingress {
    padding: 0 0 0 30px;
    position: relative;
    margin: 0 0 30px 0;

    svg {
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}
