.switch-user {
  padding: 0 16px;
  max-width: 500px;
  margin: 0 auto 100px auto;
  text-align: center;

  .retry-button {
    width: auto;
    white-space: nowrap;
    margin: 0 auto;
  }
}
