.data-display-container + .data-display-container {
  margin-top: 12px;
}

.data-display {
  &.title {
    margin-bottom: 4px;

    &.side-by-side {
      font-weight: 500;
    }

    &.list-item {
      text-transform: uppercase;
      font-size: 12px;
    }

    &.input {
      font-weight: 600;
    }
  }

  &.text {
    .amount {
      font-weight: 400;
    }

    &.list-item {
      font-weight: 600;

      .amount {
        font-weight: 600;
      }
    }

    &.input {
      font-weight: 400;

      .amount {
        font-weight: 400;
      }
    }
  }

  &.title,
  &.text {
    font-size: 14px;

    &.side-by-side {
      display: inline-block;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    &.list-item {
      display: block;
    }

    &.input {
      display: block;
    }
  }
}
