.horizontal-divider-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .horizontal-line {
    flex-grow: 1;
    height: 1px;
    background-color: var(--lysa-opacity-black-15);
  }

  .divider-text {
    padding: 0 1rem;
    .typography--label {
      color: var(--lysa-text-secondary);
    }
  }
}
