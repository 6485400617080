@use "~flag-icons/sass/flag-icons";

.fi {
  box-shadow: 0px 0px 1px 0px #acacac;
  margin-right: 8px;

  &.x2 {
    line-height: 2em;
    width: 2.666666em;
    box-shadow: 0px 0px 2px 0px #acacac;
  }
}
