@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.current-crs-corp-information {
  dl {
    .list-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    dt {
      font-weight: bold;
    }
  }
}
