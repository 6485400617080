@use "~@lysaab/ui-2/components/styling/variables";

.user-mangement-select-user {
  .card-list-item {
    padding: 0;

    .list-item {
      padding: 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      color: variables.$color-blue;
      font-weight: bold;
      font-size: 14px;
      border: none;
      cursor: pointer;
      > svg {
        margin-top: -2px;
        margin-bottom: -2px;
      }
    }
  }
}
