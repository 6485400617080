.PendingPensions {
  &__moves {
    margin-bottom: 24px;
  }

  &__steps-header {
    margin-bottom: 12px;
  }
}

.ManualSigning {
  border-bottom: 1px solid var(--lysa-opacity-black-15);
  padding-bottom: 32px;
  margin-bottom: 32px;
}
