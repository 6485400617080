@use "~@lysaab/ui-2/components/styling/variables";

.create-savings-info {
  .create-savings-info__icon-wrapper {
    display: flex;
    justify-content: center;

    .icon-primary {
      fill: var(--lysa-icon-secondary);
    }
  }

  .create-savings-info__ingress {
    margin-bottom: 4px;
  }

  .create-savings-info__ingress-link {
    margin-bottom: 16px;
  }
}
