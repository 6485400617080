@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.create-account-corporation-intro {
  .intro-ingress {
    padding: 0 0 0 30px;
    position: relative;
    margin: 0 0 30px 0;

    svg {
      position: absolute;
      top: 3px;
      left: 0;
    }
  }

  h1:first-child {
    margin: 0 0 14px 0;
  }

  .card {
    margin: 60px 0 0 0;
  }

  .question-wrapper {
    padding: 0 0 30px 0;
  }

  .experience-questions {
    padding: 20px;
    border: 1px solid variables.$color-mediumgray;
    background-color: variables.$color-canvas;
    border-radius: 10px;
  }

  .experience-question {
    margin: 0 0 50px 0;

    &:last-child {
      margin: 0;
    }
  }

  .error-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.7);
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease;
    padding: 50px 0 0 0;

    &.has-error {
      opacity: 1;
      pointer-events: all;
    }
  }

  .compressed-snackbar {
    padding: 20px 0 0 0;

    .lysa-ui-snackbar {
      & > div:first-child {
        @include media.landscape {
          flex: 0 1 auto;
        }
      }
    }
  }

  legend ul {
    list-style: disc;
    padding-left: 16px;
    margin-top: 8px;
  }
}
