@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.create-account-corporation {
  .section-divider,
  .bottom-nav {
    margin: 50px 0 0 0;
  }

  h1 {
    margin: 0 0 40px 0;
  }
}

.create-account-corporation-kyc {
  .lysa-ui-checkbox-group,
  .lysa-ui-radio {
    margin: 60px 0 14px 0;

    &:first-child {
      margin: 0 0 14px 0;
    }
  }

  .lysa-ui-checkbox-group .lysa-ui-input-group + .lysa-ui-input-group {
    margin-top: 14px;
  }
}
