.transfer-pension-sign {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10rem;

  .icon {
    margin: 2rem;
  }
}
