.monthly-entry {
  margin: 0 0 40px 0;

  dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  dt,
  dd {
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 0 0 50%;
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0 4px;
    margin: 0;
  }

  dd {
    text-align: right;
  }

  .bank-logo {
    background-size: 80%;
  }

  .monthly-ext {
    opacity: 0.7;
    font-size: 0.8em;
  }

  .amount {
    display: inline-block;
  }
}
