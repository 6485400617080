@use "~@lysaab/ui-2/components/styling/media";

.bankgiro-information-page {
  .qr-code-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;

    > .card {
      padding-bottom: 10px;
    }
  }

  .desktop-view {
    display: none;
  }

  @include media.tablet {
    .desktop-view {
      display: block;
    }

    .mobile-view {
      display: none;
    }
  }
}
