@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.system-messages-wrapper {
  padding: 0 16px;
  position: relative;

  @include media.landscape {
    padding: 0 24px;
  }

  @include media.desktop {
    padding: 0 24px;
  }

  .system-messages {
    margin: 0 auto;
    max-width: variables.$desktop-plus;
    margin-top: variables.$element-margin;

    button {
      border: 0;
      background-color: transparent;
      margin: 0;
      justify-content: center;
      align-items: center;
      height: 20px;
      cursor: pointer;
    }

    .lysa-ui-snackbar {
      margin-bottom: 0;
      justify-content: flex-start;
      flex-direction: row;
      width: 100%;
      padding-right: 18px;
      margin-top: variables.$element-margin;

      > div {
        width: 100%;
      }
    }

    .system-message {
      display: flex;
      flex-flow: row;
      width: 100%;
      align-items: center;

      .system-message-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        text-align: left;
        width: 100%;
        margin-right: auto;
        flex-grow: 1;

        *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
