.creation-status-overview {
  .signing-group {
    margin-bottom: 16px;
    margin-top: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .signing-info {
    margin-bottom: 32px;
  }
}
