@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$filter-button-witdh: 260px;
$form-width: 400px;

.filters {
  form {
    border-bottom: 2px solid variables.$color-gray;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  legend {
    font-weight: bold;
  }

  .period-filter-header {
    font-weight: bold;
  }

  .more-filters {
    margin-bottom: 2rem;
  }

  .show-more-button {
    background-color: transparent;
    border: none;
    margin-bottom: 1rem;
    cursor: pointer;

    > h3 {
      display: inline-flex;
      margin: 0;

      span {
        margin-left: 1rem;
      }

      .rotate {
        svg {
          transition: transform 500ms;
        }
        &--down {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  .button-secondary {
    width: 100%;

    & + .button-secondary {
      margin-left: 0;
    }
  }
  .reset-button {
    margin-bottom: 1rem;
  }
}
