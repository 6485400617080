.transfer-pension-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .ingress {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--lysa-text-primary);
    margin-top: -0.2rem;
    padding: 0 5rem;
  }

  .time-estimate {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    color: var(--lysa-text-secondary);
  }

  .usp-list {
    width: 100%;

    .list-item {
      display: flex;
      align-items: center;
      gap: 0rem;
      margin: 0;
      text-align: left;

      .list-item-text {
        display: flex;
        flex-direction: column;
      }

      .icon-circle {
        align-self: flex-start;
        color: var(--lysa-brand-blue);
        display: grid;
        place-items: center;
        height: 42px;
        width: 42px;
        min-width: 42px;
        min-height: 42px;
        border-radius: 100%;
      }
    }

    .read-more-button {
      background: none;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid var(--lysa-text-secondary);
      margin: 0;
      padding: 0;
    }
  }

  .why {
    width: 100%;
    text-align: start;
    margin-top: 1rem;

    .why-header {
      margin: 0 1rem;
    }

    .why-sub-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      margin-top: 2rem;
      padding: 2rem;

      .why-sub-section-header {
        margin-bottom: 1rem;
      }
    }

    .grey-bg {
      background: var(--lysa-background-card-gray-box);
    }
  }

  .contact {
    padding: 3rem;
  }

  .divider {
    width: 100%;
    border-top: 1px solid var(--color-gray-200);
    margin: 2rem 0 2rem 0;
  }

  .calculation-information {
    text-align: left;
    align-self: flex-start;
  }

  .read-more-button {
    margin: 1rem 0 2rem 0;
    background: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid var(--lysa-text-secondary);
    margin: 0;
    padding: 0;
  }

  .next {
    margin-top: 3rem;
  }
}

.transfer-pension-intro-read-more-footer {
  color: var(--lysa-text-secondary);
}
