.withdrawal-pension-done {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 4rem;

  .header {
    margin-top: -4rem;
  }

  .ingress {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--lysa-text-secondary);
    margin-top: -0.2rem;
    padding: 0 4rem;
  }

  .divider {
    border-color: hsla(0, 100%, 0%, 0.15);
    margin-top: 3rem;
  }

  .withdrawal-plan {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: start;

    dl {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0;
      gap: 16px;

      dt,
      dd {
        margin: 0;
        width: auto;
        display: flex;
        gap: 0.6rem;
        align-items: center;
      }
    }

    .tax-rate-information-button {
      border: none;
      min-height: 0;
      height: 18px;
      width: 18px;
    }
  }

  .withdrawal-account {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: start;

    .withdrawal-account-row {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1.6rem 2rem;
      background-color: var(--color-gray-50);
      border-radius: 12px;

      span {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
