.share-account-intro-list {
  ul {
    margin-left: 14px;
  }

  li {
    margin-bottom: 20px;
    align-items: center;
    display: flex;

    &:last-of-type {
      margin-bottom: 0;
    }

    svg {
      margin-right: 14px;
      height: 20px;

      .icon-primary {
        fill: var(--lysa-icon-secondary);
      }
    }

    div:first-child {
      display: flex;
    }
  }
}
