.pension-video-wrapper {
  display: block;
  border-radius: var(--lysa-card-border-radius);
  position: relative;

  video {
    border-radius: var(--lysa-card-border-radius);
    width: 100%;
  }

  video::-webkit-media-controls-panel {
    background-image: linear-gradient(transparent, transparent) !important;
  }

  //   video::-webkit-media-controls {
  //     filter: invert(0) !important;
  //   }
}

.video-player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.play-button {
  display: block;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 7px 0 7px 11px;
  border-radius: 0;
  margin-left: 4px;
}
