.monthly-internal-story-confirm {
  dl {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .monthly-internal-story-confirm__entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    text-align: right;

    dt {
      display: flex;
      .typography--body {
        white-space: nowrap;
      }
    }

    dd {
      display: flex;
      align-items: center;
      gap: 8px;
      min-width: 0;

      .typography--label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        flex-shrink: 0;
      }
    }
  }
}
