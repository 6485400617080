@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

$space-around-marker: 1rem;
$mobile-border: 6px double variables.$color-black;

.expandable-table {
  @include media.desktop {
    padding-top: 1.5rem;
    padding-left: $space-around-marker;
  }
  @media (max-width: variables.$desktop) {
    margin-bottom: 2rem;

    table {
      margin-bottom: 0;
    }

    tr {
      border-bottom: $mobile-border;
    }
  }

  .expandable-table-marker {
    width: 100%;

    @include media.desktop {
      border-left: 5px solid variables.$color-gray;
      padding-left: $space-around-marker;
    }

    @media (max-width: variables.$desktop) {
      border-left: $mobile-border;
      padding-left: 1rem;
    }
  }

  &.expandable-table--height-auto {
    height: auto;
    background-color: variables.$color-white;
  }
}
