.transfer-pension-intro-info {
  .lysa-ui-snackbar.lysa-snackbar-info {
    background-color: var(--lysa-background-canvas);
  }

  .illustration {
    width: 128px;
    height: 128px;
  }
}
