@use "~@lysaab/ui-2/components/styling/media";

.withdrawal-story {
  .withdrawal-item {
    margin: 30px 0;

    @include media.tablet {
      margin: 50px 0;
    }
  }
  .horizontal-divider-wrapper {
    margin-top: 32px;
  }
}
