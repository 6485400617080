.PensionInsuranceDetails {
  .header {
    word-break: break-all;
  }

  .item-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  dd {
    margin: 0;
  }

  .side-by-side {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    dt {
      flex: 1;
    }

    dd {
      text-align: right;
    }
  }
}
