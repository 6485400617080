.transfer-pension-story-payout-plan {
  .read-more {
    margin: 0;
    padding: 0.2rem 0 !important;
    min-height: auto;
    line-height: normal;

    .typography--label {
      line-height: 16px;
    }
  }

  .lysa-ui-radio-card-group {
    width: 100%;
  }

  /* Override default style to align with design */
  .label-row {
    padding: 1.4rem;
  }

  .beneficiary-header {
    margin-top: 0;
  }

  .snack-bar {
    margin-top: -1rem;
  }

  .download {
    margin: 1rem;
    padding: 2rem;
    border-radius: 1rem;
    background-color: var(--color-gray-50);
  }
}
