@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.contact-page {
  .contact-totp {
    text-align: center;
    font-size: 4em;
    padding-top: 1em;
  }
}
