.share-account-invitation-page {
  max-width: 500px;
  margin: 0 auto;

  .as-link {
    padding: 0;
  }

  ul {
    margin-top: 4px;
    list-style: disc;
    margin-left: 18px;

    li {
      margin-bottom: 4px;
    }
  }

  .list-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 1em;
    }
  }
  hr {
    margin-bottom: 12px;
  }

  .summary-card {
    .lysa-ui-snackbar {
      margin-bottom: 0;
    }

    .small {
      opacity: 0.75;
      text-align: center;
      margin-bottom: 0;
    }
  }
}
