.swish-deposit-qr {
  .qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    .open-button {
      margin-top: 24px;
    }

    img {
      width: 200px;
    }
    .qr-desktop {
      display: flex;
      align-items: center;
      ol {
        list-style: revert;
      }
    }
    .lysa-ui-spinner {
      width: 40px;
    }
  }
}
