@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

$row-height: 4.5rem;
$table-side-padding: 1rem;

.lysa-table {
  width: 100%;
  margin-bottom: 1rem;
  text-align: right;

  @media (min-width: #{variables.$desktop + 1px}) {
    display: table;

    thead {
      color: var(--lysa-text-white);
      font-weight: 500;

      tr {
        th {
          background-color: var(--lysa-background-table-header);

          &:first-child {
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
          }
        }
      }
    }

    tr {
      height: $row-height;
    }

    th:first-child,
    td:first-child {
      padding-left: $table-side-padding;
    }

    th:last-child,
    td:last-child {
      padding-right: $table-side-padding;
    }

    &--zebra {
      tbody {
        > tr:nth-child(odd) {
          background: var(--lysa-background-table-row-blue);
        }
      }
    }
    &--fixed {
      table-layout: fixed;
    }

    &--small {
      tr,
      td {
        height: 1rem;
      }
    }
  }

  @media (max-width: variables.$desktop) {
    thead {
      display: none;
    }

    tr {
      border-bottom: 2px solid var(--lysa-border-black);
    }

    tr:last-child {
      border-bottom: none;
    }

    td {
      display: flex;
      justify-content: space-between;
      min-height: $row-height;
    }

    td:first-child {
      margin-top: 2rem;
    }

    td:before {
      content: attr(data-label);
      font-weight: bold;
      text-align: left;
    }

    &--small {
      tr,
      td {
        height: 2rem;
      }

      td:first-child {
        margin-top: 1rem;
      }

      td:last-child {
        margin-bottom: 1rem;
      }
    }
  }
}
