@use "~@lysaab/ui-2/components/styling/media";

.banner-redirect {
  padding: 30px 20px;
  display: grid;
  text-align: center;
  align-content: center;
  max-width: 450px;
  grid-template-columns: 100%;
  margin: 0 auto;
  overflow: hidden;

  .content {
    margin: auto;

    .body {
      margin: 20px 0;
    }

    .badges {
      margin-bottom: 30px;

      .app-store-badge {
        margin-right: 10px;
      }
    }

    .go-back {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-primary {
        fill: var(--lysa-button-primary-enabled);
      }
      &:hover {
        .icon-primary {
          fill: var(--lysa-link-hover);
        }
      }
    }
  }

  .mobile {
    width: 100%;
    margin: 30px auto;
    align-self: center;
  }

  @include media.tablet {
    grid-template-columns: 3fr 3fr;
    text-align: left;
    height: 100vh;
    padding: 0 30px;
    max-width: 900px;
    align-content: center;

    .content {
      width: 100%;

      .body {
        margin: 30px 0;
      }

      .go-back {
        justify-content: start;
      }
    }

    .mobile {
      max-width: 320px;
      max-height: 384px;
    }
  }
}
