.company-settings--principals {
  .info-list {
    > li {
      padding: 4px 0;
      display: flex;
      justify-content: space-between;

      > span:first-child {
        font-weight: bold;
      }
    }
  }
}
