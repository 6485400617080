@use "~@lysaab/ui-2/components/styling/media";

.confirmation-text-section {
  @include media.tablet {
    font-size: 16px;
    line-height: 1.8;
  }

  .confirmation-text {
    text-align: left;
    margin-bottom: 14px;

    ul {
      margin-top: 4px;
      list-style: disc !important;
      margin-left: 18px;

      li {
        margin-bottom: 4px;
      }
    }
  }
}
