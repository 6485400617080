@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.accounting-page {
  .transactions {
    margin-top: 30px;
  }

  .only-print {
    display: none;
  }

  .company {
    .spinner-container {
      top: 0;
      left: 0;
      width: 100%;
    }

    .info-box {
      padding: 0;
    }

    .company-details {
      .print-button {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 18px;

        @include media.desktop {
          margin-bottom: 0px;
        }
      }

      .title {
        width: 140px;
      }
    }
  }

  .account-info {
    margin-top: 30px;
    margin-bottom: 0;
    color: variables.$color-darkgray;
  }

  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }

    .only-print {
      display: initial;
    }

    .company-title {
      margin-top: 100px;
      margin-bottom: 50px;

      .col {
        flex: 75%;

        & + .col {
          flex: 25%;
        }
      }
    }

    .transactions {
      margin-top: 50px;
    }
  }
}
