@use "~@lysaab/ui-2/components/styling/media";

.confirmation-summary {
  margin-top: 24px;

  @include media.tablet {
    font-size: 16px;
    line-height: 1.8;
  }

  .confirmation-summary__risk-indicator {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    .risk-indicator {
      margin: 0 0 0 5px;
    }
  }
}
