@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

:root {
  --lysa-graph-menu-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

[data-theme="dark"] {
  --lysa-graph-menu-box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.4);
}

.graph-menu {
  min-height: 20px;
  width: 100%;
  max-width: 400px;
  right: 0;
  top: 0;
  position: absolute;
  background-color: var(--lysa-background-screen);
  padding: 16px 16px 30px 16px;
  transition: opacity 300ms ease;
  opacity: 0;
  z-index: 100;
  pointer-events: none;
  border-radius: 10px;

  @include media.tablet {
    border-radius: 10px;
    box-shadow: var(--lysa-graph-menu-box-shadow);
    left: auto;
    right: 10px;
    top: 8px;
    padding: 14px;
  }

  .flex {
    margin: 0 0 16px 0;

    .graph-menu-actions {
      display: flex;
      flex-direction: row;
    }
  }

  .lysa-ui-button {
    cursor: pointer;
    align-items: center;

    svg {
      margin: 0;
    }
  }

  .market-item {
    padding: 10px 30px 8px 0;
    position: relative;
    border: 0;
    display: block;
    border-bottom: 1px solid variables.$color-gray;
    background-color: var(--lysa-background-screen);
    width: 100%;

    @include media.tablet {
      width: 100%;
      margin: 0;
    }

    &:first-child {
      border-top: 1px solid variables.$color-gray;
    }

    span {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      display: inline-block;
      margin: 0 6px 0 0;
      position: relative;
      top: 1px;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    svg {
      position: absolute;
      top: 4px;
      right: 6px;
      transition: opacity 300ms ease;
      opacity: 0;
    }

    &.active {
      svg {
        opacity: 1;
      }
    }
  }

  .card-list-item {
    padding: 0;
    position: relative;
  }

  button {
    margin: 0;
    padding: 0;
    text-align: left;
    cursor: pointer;
  }
}

.graph-card.show-menu {
  .graph-menu {
    opacity: 1;
    pointer-events: all;
  }
}
