@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-actions {
  padding: 40px 0 0 0;

  .account-actions-buttons {
    display: flex;
    gap: 0px;
    flex-direction: column;

    @include media.tablet {
      flex-direction: row;
      gap: 20px;

      .lysa-ui-button {
        margin: 0;
      }
    }

    @include media.desktop-plus {
      gap: 40px;
    }
  }
}
