@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-account-allocation {
  height: 100%;
  display: flex;
  flex-direction: column;

  .card-list {
    height: 100%;
  }

  .account-page-padder {
    height: 100%;
    justify-content: space-between;
    display: flex;
    align-content: space-between;
    flex-direction: column;
  }

  @include media.tablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
