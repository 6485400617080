@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-actions {
  .account-actions__button {
    margin-bottom: 1rem;

    @include media.tablet {
      margin-bottom: 0;
    }
  }
}
