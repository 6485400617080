@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.confirm-crs-information {
  .list-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    dt {
      font-weight: bold;
    }
  }
  .list-row:last-child {
    margin-bottom: 2rem;
  }
}
