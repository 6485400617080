.monthly-story-confirm {
  .monthly-story-confirm__entry {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    text-align: right;

    .monthly-story-confirm__element--bold {
      font-weight: bold;
    }
  }
}
