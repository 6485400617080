@use "~@lysaab/ui-2/components/styling/variables";

.finland-tax-details {
  h3,
  h4 {
    margin-bottom: 8px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .label {
    margin-right: 16px;
  }

  .tax-header-left {
    max-width: 400px;
    width: 100%;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
  }
  dt {
    width: 50%;
    font-weight: 600;
  }
  dd {
    margin-left: auto;
    width: 50%;
    text-align: right;
  }

  .tax-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .tax-print-button {
    margin-top: 12px;
  }

  .tax-reporting-information {
    margin-top: 24px;
  }

  .double-table-row {
    display: flex;
    flex-direction: column;

    span:nth-child(2) {
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 4px;
    }
  }
}
