@use "~@lysaab/ui-2/components/styling/media";

.graph-card.show-overlay {
  .interval-custom-dates {
    opacity: 0.4;
  }
}

.interval-custom-dates {
  z-index: 500;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transition: height 300ms ease, opacity 300ms ease;

  & > div {
    display: flex;
  }

  &.is-active {
    opacity: 1;
    overflow: visible;
  }

  .lysa-ui-input-group {
    margin: 0 4px 0 0;

    @include media.desktop {
      margin: 0 20px 0 0;
    }

    &:last-child {
      margin: 0 0 0 4px;
    }
  }

  .lysa-ui-input {
    input {
      padding: 8px 20px 8px 10px;
    }

    .lysa-ui-input-icon {
      top: 9px;
      right: 9px;
    }
  }

  .lysa-ui-datepicker .react-datepicker__day--disabled {
    opacity: 0.3;

    &:hover {
      background-color: transparent;
    }
  }
}
