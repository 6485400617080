@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";
$height: 60px;

.message-list {
  margin: -2px;

  .message-list-button {
    width: 100%;
    height: $height;
    border-top: 1px solid variables.$color-mediumgray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    padding: 0 14px;

    & > span:last-child {
      opacity: 0.7;
      font-size: 13px;
    }

    &.is-read {
      font-weight: normal;
    }
  }
  /* 
  .thread {
    padding: 10px 16px 40px 16px;
  } */

  & > li:first-child {
    .message-list-button {
      border-top: 0;
    }
  }
}
