.finland-tax-page {
  p {
    margin-bottom: 15px;
  }
  .tax-print-button {
    display: flex;
    justify-content: space-around;
    margin-left: 24px;
  }
}
