@use "~@lysaab/ui-2/components/styling/media";

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
}

$author-width: 34px;
$date-width: 100px;
$height: 19px; // (row height (62px) - padding (2 * 12px)) / 2;

.thread-item {
  .message-details {
    margin-top: 10px;

    .attachment-link {
      display: inline-flex;
      align-items: center;
    }

    .attachment-icon {
      margin-left: 4px;
      display: inline-flex;
      align-items: center;
    }
  }

  .message-subject {
    display: inline-block;
    width: calc(100% - #{$author-width});

    @include media.tablet {
      padding: 0 6px;
      width: calc(100% - #{$author-width + $date-width});
    }

    h6 {
      font-size: 13px;
      margin: 0;
      height: $height;
      line-height: $height;
      padding: 0 #{$date-width} 0 0;
      @include ellipsis();
      width: 100%;
      display: block;

      @include media.tablet {
        font-size: 14px;
      }
    }
  }
}
