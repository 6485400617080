@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.profile-page {
  .profile-page-links {
    max-width: 800px;

    .profile-page-link {
      margin-bottom: 10px;

      @include media.tablet {
        margin-bottom: 20px;
      }

      .profile-messages-indicator {
        background: variables.$color-red;
        margin-left: 7px;
        margin-bottom: 0;
      }
    }
  }
}
