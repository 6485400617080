.periodic-withdrawal-examples {
  margin-top: 30px;

  .examples-container {
    margin-bottom: 40px;

    .withdrawal-item {
      margin-bottom: 20px;
    }
  }
}
