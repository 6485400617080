@use "~@lysaab/ui-2/components/styling/variables";

.confirm-esg-update {
  &--card-divider {
    border: 1px solid variables.$color-gray;
  }

  &--overridden {
    text-decoration: line-through;
  }

  &--grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &--grid-container span:nth-child(even) {
    text-align: right;
  }

  &--info {
    margin-top: 32px;
  }

  &--body {
    margin: 32px 0;
  }
}
