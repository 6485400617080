.invite-information {
  .invite-information-code-header {
    max-width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 auto;
  }

  .invite-information-disclaimer {
    margin-top: 3rem;
    font-size: 1.2rem;
  }
}
