.monthly-internal-transfer-entry {
  margin: 4px 0 4px 0;

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      a {
        margin-left: 8px;
      }
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
    margin: 0;
    padding: 0;
  }

  dt {
    display: flex;
    margin-bottom: 4px;

    .typography--body {
      white-space: nowrap;
    }
  }

  dd {
    display: flex;
    min-width: 0;
    gap: 8px;
    align-items: center;
    margin-left: 0;
    padding-bottom: 4px;
    svg {
      flex-shrink: 0;
    }
  }

  .entry-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
  }

  .entry-row:last-of-type {
    border-bottom: none;
  }

  .account-name__text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .monthly-ext {
    opacity: 0.7;
    font-size: 0.8em;
  }

  .amount {
    display: inline-block;
  }

  .monthly-internal-transfer-entry__confirm-card {
    border-radius: 0;
    box-shadow: none;
    border: none;
  }
}
