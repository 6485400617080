.npsModalContainer {
  .iconWrapper {
    width: fit-content;
    max-width: 280px;
    margin: 0 auto;

    .closeModalButton {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .npsRating {
    display: flex;
    flex-direction: row;
    height: 48px;
    width: 100%;
  }

  .npsRatingButton {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-right: 0;
    border-left: 1px solid var(--color-black);
    border-top: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
  }

  .npsRatingButton:first-child {
    border-left: 1px solid var(--color-black);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .npsRatingButton:last-child {
    border-right: 1px solid var(--color-black);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .npsScale {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 0 18px 0;
  }

  .scaleText {
    color: var(--lysa-text-secondary);
  }
}
