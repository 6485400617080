@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

:root {
  --lysa-graph-grid-lines: var(--color-black);
}

[data-theme="dark"] {
  --lysa-graph-grid-lines: var(--color-white);
}

.performance-graph {
  position: relative;

  svg {
    overflow: visible;
    position: relative;
    z-index: 6;
  }

  .lysa-performance-line {
    fill: none;
    stroke-width: 2px;
    stroke: variables.$color-blue;
  }

  .lysa-performance-shadow {
    fill: none;
    stroke-width: 2px;
    stroke: variables.$color-primary-hover;
    stroke-opacity: 0.5;
  }

  .graph-events {
    transition: opacity 300ms ease;
    opacity: 1;
  }

  .y-axis-ticks,
  .x-axis-ticks,
  .y-axis-lines,
  .x-axis-lines {
    transition: opacity 300ms ease;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    pointer-events: none;
  }

  .y-axis-ticks {
    z-index: 7;

    & > div {
      position: absolute;
      top: -4px;
      right: -25px;
      display: inline-block;
      font-size: 1rem;
      line-height: 1rem;
      height: 14px;
      font-weight: bold;

      @include media.tablet {
        right: -36px;
        top: -7px;
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
  }

  .x-axis-line,
  .y-axis-line {
    position: absolute;
    left: 0;
    opacity: 0.1;
    background-color: var(--lysa-graph-grid-lines);
  }

  .y-axis-line {
    height: 1px;
    width: 100%;
  }

  .x-axis-line {
    width: 1px;
    height: 100%;
  }

  .x-axis-ticks {
    top: 100%;
    height: auto;
    font-style: italic;
    opacity: 0.7;

    & > div {
      position: absolute;
      left: 0;
      top: 0;
      min-width: 60px;
      margin: 0 0 0 -30px;
      font-size: 1rem;
      line-height: 1rem;
      height: 14px;
      text-align: center;

      @include media.tablet {
        top: 4px;
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
  }

  &.is-animating {
    .graph-events,
    .y-axis-ticks,
    .x-axis-ticks,
    .y-axis-lines,
    .x-axis-lines {
      opacity: 0;
    }
  }

  .index-performance-line-0,
  .index-performance-line-1,
  .index-performance-line-2 {
    fill: none;
  }

  .performance-graph-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 300ms ease;
  }

  rect {
    fill: none;
    stroke: none;
    pointer-events: all;
  }

  .performance-line-indicator {
    width: 1px;
    height: calc(100% + 42px);
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);

    @include media.landscape {
      height: calc(100% + 50px);
    }
  }

  .performance-marker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin: -8px 0 0 -8px;
    background-color: rgba(24, 64, 227, 0.3);

    & > div {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin: 4px;
      background-color: variables.$color-blue;
    }
  }

  .event-indicator {
    stroke: variables.$color-blue;
    fill: variables.$color-blue;
    fill-opacity: 0.3;
    opacity: 0;
    transition: opacity 300ms ease;
  }

  .event-element {
    &.WITHDRAWAL {
      fill: var(--lysa-indicator-withdrawal);
      fill-opacity: 0.5;
    }

    &.DEPOSIT {
      fill: var(--lysa-indicator-deposit);
      fill-opacity: 0.5;
    }

    &.INTERNAL {
      fill: var(--lysa-indicator-internal);
      fill-opacity: 0.5;
    }
  }
}

.graph-card.show-overlay {
  .performance-graph-overlay {
    opacity: 1;
  }

  .event-indicator {
    &.active {
      opacity: 0.4;
    }
  }
}
