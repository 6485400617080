.waiting-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.rejected-status {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

.retry-status {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

.sign-move-list-item {
  margin: 0;
}
