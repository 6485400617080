@use "~@lysaab/ui-2/components/styling/variables";

.transactions-card {
  .transaction-card__list {
    position: relative;

    .transaction-card__entry {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;

      .transaction-card__entry-left {
        width: 50%;
      }

      .transaction-card__entry-right {
        text-align: right;
        width: 50%;
      }

      .transaction-card__entry-indicator {
        display: inline-block;
        margin: 0 4px 0 0;
        border-radius: 2px;
        width: 10px;
        height: 10px;
        vertical-align: middle;
      }

      .transaction-card__deposit-indicator {
        background-color: var(--lysa-indicator-deposit);
      }
      .transaction-card__withdrawal-indicator {
        background-color: var(--lysa-indicator-withdrawal);
      }
      .transaction-card__internal-transfer-indicator {
        background-color: var(--lysa-indicator-internal);
      }
      .transaction-card__interest-indicator {
        background-color: var(--lysa-indicator-deposit);
      }
      .transaction-card__fee-indicator {
        background-color: var(--lysa-indicator-withdrawal);
      }
    }

    .transaction-card__list-fade {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
}
