@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$invoice-table-border: 2px solid variables.$color-gray;
$invoice-table-padding: 1rem;
$a4-width: 21cm;
$a4-height: 29.7cm;
$page-padding: 55px;
$margin-invoice-block: 4rem;

.invoice-page {
  padding-bottom: 2rem;

  @include media.desktop {
    width: $a4-width;
    margin: 0 auto;
  }

  .invoice-page-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem;

    &__print-button {
      display: none;

      @include media.landscape {
        flex-basis: 140px;
        display: flex;
        align-items: center;
      }
    }
  }

  .invoice {
    display: flex;
    flex-direction: column;
    height: $a4-height;
    margin: 0 auto;
    padding-top: $page-padding;

    @include media.desktop {
      padding: $page-padding;
    }

    .invoice-header {
      margin-bottom: $margin-invoice-block;
      font-size: 1.2rem;

      .invoice-header__details {
        font-weight: bold;
        overflow-wrap: anywhere;
        margin-left: 2rem;
      }
    }

    .invoice-body {
      .invoice-body-header {
        font-size: 2rem;
      }

      .invoice-body-text {
        font-weight: bold;
      }

      .invoice-body-table {
        border: $invoice-table-border;
        margin: 0 0 $margin-invoice-block 0; // Overwrite GridRow negative margin

        .invoice-body-table__cell {
          padding: $invoice-table-padding;
          border-bottom: $invoice-table-border;

          &--bold {
            font-weight: bold;
          }

          &:last-child {
            border-bottom: none;
          }
          &:first-child {
            @include media.landscape {
              border-right: $invoice-table-border;
            }
          }
        }
      }

      .invoice-body-summary {
        display: flex;
        justify-content: space-between;
        font-size: 1.8rem;
      }
    }

    .invoice-body-footer {
      font-size: 1.2rem;
      color: variables.$color-gray;
      margin-top: auto;
    }
  }

  @media print {
    .invoice-page-menu {
      display: none !important;
    }
    .invoice {
      width: $a4-width;
      height: $a4-height;
    }
  }
}
