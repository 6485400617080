.kyc-view-page {
  h4 {
    margin-bottom: 0;
  }

  ul {
    margin: 10px 0 20px 20px;
    list-style-type: disc;
  }
  .lysa-ui-form + .lysa-ui-form {
    margin-top: 50px;
  }
}
