@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.withdrawal-page-pending-periodic {
  margin: 0 0 0 0;

  h5 {
    font-weight: 500;
  }

  .remove-button {
    margin: 10px 0 0 0;
    text-align: right;
  }

  .withdrawal-page-pending-periodic__pending-item {
    .pending-withdrawal-periodic:last-child {
      margin-bottom: 0;
    }
  }
}

.pending-withdrawal-periodic {
  position: relative;
}
