@use "sass:math";
@use "~@lysaab/ui-2/components/styling/variables";

$diameter: 12px;

.notification-dot {
  background-color: variables.$color-red;
  height: $diameter;
  position: absolute;
  right: -(math.div($diameter, 2));
  top: 0;
  width: $diameter;
  border-radius: $diameter;
  border: 2px solid variables.$color-canvas;
}
