.transfer-pension-story-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;
  font-size: 14px;
  line-height: 22px;
  color: var(--lysa-text-secondary);
  margin-top: 24px;

  .hr {
    border-top: 1px solid var(--lysa-border-gray);
    border-bottom: 0;
    width: 100%;
  }

  .address {
    font-style: normal;
  }

  .privacy-policy {
    display: flex;
    justify-content: center;
    gap: 4px;
    line-height: 24px;
  }
}
