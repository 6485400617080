.accepted-owner-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .emoji {
    font-size: 3em;
  }

  .text {
    text-align: center;
    margin: 0;
  }
}
