.document-page-menu {
  h3,
  h4 {
    margin-bottom: 12px;
  }

  h3 + h4 {
    margin-top: 12px;
  }
  li {
    margin-bottom: 6px;
  }
}
