.share-account-confirm {
  .list-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 1em;
    }
  }

  .non-lysa-customer-section {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .disclaimer {
    padding: 20px 12px;
    font-size: 1.2rem;
    margin: 0 auto;
    margin-top: -30px;
  }

  .as-link {
    padding: 0;
    text-align: right;
  }
}
