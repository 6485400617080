@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.graph-card {
  position: relative;
  border: 0;
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;

  &.show-overlay {
    .performance-graph-info {
      opacity: 0;
    }
  }

  h4 {
    span {
      font-weight: 400;
      opacity: 0.4;
      margin: 0 0 0 6px;
    }
  }

  .graph-card-plot {
    overflow: hidden;
    margin: 10px 0 0 0;
  }

  .graph-card-wrapper {
    position: relative;
  }

  .graph-wrapper {
    position: relative;
  }

  .retry-error {
    margin: 0 16px;
    width: calc(100% - 32px);

    @include media.tablet {
      margin: 0 24px;
      width: calc(100% - 48px);
    }
  }

  .button-circle.graph-menu-open {
    span {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: variables.$color-blue;
      display: inline-block;
    }
  }

  .graph-info {
    background-color: var(--lysa-background-canvas);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0;
    padding: 5px 16px 20px 16px;

    @include media.landscape {
      padding: 10px 24px 20px 24px;
    }

    @include media.tablet {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
    }

    @include media.desktop {
      padding: 6px 24px 10px 24px;
      flex-wrap: wrap;
    }

    .interval-switch {
      margin-right: auto;
      padding-right: 8px;

      @include media.desktop {
        min-width: 0;
        flex: 0 0 auto;
        order: 1;
        display: flex;
      }

      @media (min-width: 1200px) {
        margin: 16px auto 10px 0;
      }
    }

    .events-switch {
      margin: 14px 0 0 0;

      @include media.desktop {
        order: 2;
        flex: 0 0 auto;
        margin: 0;
      }

      @media (min-width: 1200px) {
        order: 3;
      }
    }

    .interval-custom-dates {
      margin-right: 8px;
      width: 100%;

      @include media.desktop {
        order: 3;
      }

      @media (min-width: 1200px) {
        order: 2;
        flex: 0;
        display: flex;
        align-content: flex-start;

        &.is-active {
          flex: 1 0 300px;
        }
      }
    }
  }
}

.graph-card-top {
  display: flex;
  justify-content: space-between;
  transition: opacity 300ms ease;
  opacity: 1;
  padding: 16px 16px 0 16px;

  @include media.landscape {
    padding: 22px 24px 0 24px;
  }
}

.graph-card.show-overlay {
  .graph-card-top {
    opacity: 0.4;
  }
}
