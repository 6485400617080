@use "~@lysaab/ui-2/components/styling/media";

.tax-redemption-modal {
  .tax-redemption-modal__table-wrapper {
    margin-bottom: 6rem;

    .tax-redemption-modal__table {
      th,
      td {
        text-align: right;

        @include media.desktop {
          padding-left: 10px;
        }
      }
    }

    .tax-redemption-modal__table-total-wrapper {
      display: flex;
      justify-content: flex-end;

      @include media.desktop {
        padding-right: 10px;
      }
    }

    .tax-redemption-modal__table-total {
      display: flex;
      flex-direction: column;
      text-align: right;
    }
  }
}
