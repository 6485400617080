@use "~@lysaab/ui-2/components/styling/media";

.savings-summary-number {
  &__label {
    color: var(--lysa-text-secondary);
  }

  &__amount {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.25;
    transition: filter 300ms ease;

    @include media.tablet {
      font-size: 48px;
    }

    &--large {
      font-size: 34px;
      line-height: 1.16666;

      @media (min-width: 360px) {
        font-size: 40px;
      }

      @media (min-width: 410px) {
        font-size: 48px;
      }
    }

    &--loading {
      filter: blur(8px);
    }
  }
}
