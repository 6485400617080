@use "~@lysaab/ui-2/components/styling/media";

.account-page-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  .card,
  .card-list {
    flex-grow: 1;
    padding: 0;
    border: 0;
  }

  .account-page-card__flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .account-page-card__body {
      flex: 1 0 auto;
    }

    .account-page-card__bottom {
      flex: 0 0 auto;
    }
  }

  .account-page-card__body {
    padding: 17px 16px;

    @include media.landscape {
      padding: 17px 22px;
    }
  }

  .account-page-card__bottom {
    text-align: center;
    background-color: var(--lysa-background-card-bottom);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    a {
      padding: 14px 16px;
      width: 100%;
      display: block;
      font-weight: 500;

      @include media.landscape {
        padding: 14px 24px;
      }
    }
  }
}
