.invite-information {
  max-width: 600px;

  .invite-information-card {
    width: 100%;
    margin-bottom: 16px;
  }

  .invite-information-code-header {
    max-width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 auto;
  }

  .invite-information-disclaimer {
    font-size: 1.2rem;
    margin-top: 16px;
  }
}
