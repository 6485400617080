.withdrawal-story-external {
  .lysa-ui-form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .withdrawal-story-external__external-accounts-wrapper {
    .lysa-ui-radio-card-group {
      margin-bottom: 12px;
      .lysa-ui-radio-card-group-card.card {
        margin-bottom: 0;
      }
    }
  }

  .add-withdrawal-account-wrapper {
    display: flex;
    justify-content: center;
  }
}
