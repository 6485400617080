@use "./GridMixins.scss";

.lysa-ui-row {
  display: flex;
  flex-wrap: wrap;
  @include GridMixins.make-row-margins();

  > .col {
    flex-grow: 1;

    &.disabled-flex-grow {
      flex-grow: 0;
    }

    @include GridMixins.make-col-paddings();
  }

  &.flex-evenly {
    flex: 1;

    > .col {
      flex: 1;
    }
  }
}

@include GridMixins.make-grid();

$justify-content-values: (
  start: flex-start,
  end: flex-end,
  center: center,
  around: space-around,
  between: space-between,
  evenly: space-evenly,
);

$align-items-values: (
  stretch: stretch,
  start: flex-start,
  end: flex-end,
  center: center,
  baseline: baseline,
);

$align-self-values: (
  stretch: stretch,
  start: flex-start,
  end: flex-end,
  center: center,
  baseline: baseline,
);

/* Classes will have name like ".justify-content-${$justify-content-values[0]}
 * e.g .justify-content-start, .align-items-stretch, align-self-end */
@include GridMixins.create-classes-from-value-array(
  "justify-content",
  $justify-content-values
);

@include GridMixins.create-classes-from-value-array(
  "align-items",
  $align-items-values
);

@include GridMixins.create-classes-from-value-array(
  "align-self",
  $align-self-values
);
