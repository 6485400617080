.transfer-pension-snail-mail {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
    }
  }

  .need-action {
    color: #ff7576;
    font-size: 12px;
    margin-top: 0;
  }

  .pensions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }
}
