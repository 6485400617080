.transfer-pension-docs {
  .link-container {
    display: flex;
    flex-direction: column;

    .lysa-link-button {
      text-align: left;
    }

    .link {
      padding-top: 8px;
    }

    .custom-link-container {
      padding-top: 8px;
    }

    .custom-link {
      display: inline;
      font-weight: bold;
      color: var(--lysa-link-primary);
      background-image: linear-gradient(
        var(--lysa-link-primary),
        var(--lysa-link-primary)
      );
      background-position: 100% 100%;
      background-repeat: no-repeat;
      background-size: 100% 2px;
      transition: background-position 0.3s ease-in, background-size 0.3s ease-in;
      cursor: pointer;
    }

    .custom-link:hover {
      background-image: linear-gradient(
        var(--lysa-link-hover),
        var(--lysa-link-hover)
      );
      background-position: 100% 100%;
      background-size: 0 2px;
      color: var(--lysa-link-hover);
    }
  }
}
