@use "~@lysaab/ui-2/components/styling/media";

.retry-error {
  .lysa-ui-snackbar {
    flex-direction: column;
    padding: 16px;

    @include media.landscape {
      padding: 25px;
      flex-direction: row;
    }

    & > div {
      margin: 10px 0 0 0;
      width: 100%;

      @include media.landscape {
        flex: 0 0 auto;
        width: auto;
        margin: 0 0 0 20px;
      }
    }

    & > span {
      @include media.landscape {
        flex: 0 0 auto;
      }
    }
  }
}
