@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.open-new-account {
  @include media.tablet {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(50% - 20px);
  }

  @include media.desktop {
    width: calc(33.33% - 20px);
  }

  @include media.desktop-plus {
    width: calc(33.33% - 40px);
  }

  p {
    flex: 1 0 auto;
  }

  .open-new-account-action {
    border-radius: 10px;
    border: 1px dashed #adadeb;
    padding: 14px;
    font-weight: 500;
    color: variables.$color-blue;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 0 38px 0;
    flex: 0 0 auto;

    &:visited {
      color: variables.$color-blue;
    }

    svg {
      transform: rotate(45deg);
      margin: 0 0 0 6px;
      width: 12px;
      height: 12px;
    }
  }
}
