@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$bank-NORDEA: #0065a3;
$bank-SEB: #5bc718;
$bank-HANDELSBANKEN: #0267b5;
$bank-SWEDBANK: #313840;
$bank-ICA: #eb1f07;
$bank-LANSFORSAKRINGAR: #196baa;
$bank-SBAB: #ff510d;
$bank-LANDSHYPOTEK: #3e92ad;
$bank-FOREX: #fbd01d;
$bank-SKANDIABANKEN: #018c70;
$bank-DANSKE_BANK: #01426b;
$bank-MARGINALEN: #e31937;
$bank-SPARBANKEN_SYD: #f0c514;
$bank-SPARBANKERNA: #ebe7e2;

.bank-logo {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: middle;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

.AVANZA {
  background-image: url("./bankLogos/avanza.svg");
  background-size: 100%;
}

.BORGO {
  background-image: url("./bankLogos/borgo.svg");
  background-size: 100%;
}

.NORDEA {
  background-image: url("./bankLogos/nordea.svg");
  background-size: 100%;
}

.NORDNET {
  background-image: url("./bankLogos/nordnet.svg");
  background-size: 100%;
}

.ALANDSBANKEN {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-lightgray;
  background-image: url("./bankLogos/alandsbanken.png");
  background-size: 100%;
}

.SWEDBANK {
  background-image: url("./bankLogos/swedbank.svg");
  background-size: 100%;
}

.SEB {
  background-image: url("./bankLogos/SEB.svg");
  background-size: 100%;
}
.HANDELSBANKEN {
  background-image: url("./bankLogos/handelsbanken.svg");
  background-size: 100%;
}
.ICA {
  background-image: url("./bankLogos/ica.svg");
  background-size: 100%;
}
.IKANO {
  background-image: url("./bankLogos/ikano.svg");
  background-size: 100%;
}
.LANSFORSAKRINGAR {
  background-image: url("./bankLogos/lansforsakringar.svg");
  background-size: 100%;
}
.SBAB {
  background-image: url("./bankLogos/SBAB.svg");
  background-size: 100%;
}
.RESURS {
  background-image: url("./bankLogos/resurs.svg");
  background-size: 100%;
}
.LANDSHYPOTEK {
  background-color: $bank-LANDSHYPOTEK;
  background-image: url("./bankLogos/Landshypotek.svg");
  background-size: 75%;
  background-position: center 45%;
  @include media.tablet {
    background-size: 70%;
    background-position: center 46%;
  }
}
.FOREX {
  background-color: $bank-FOREX;
  background-image: url("./bankLogos/FOREXBANK.svg");
  background-size: 100%;
  background-position: center 53%;
  @include media.tablet {
    background-size: 80%;
    background-position: center;
  }
}
.SKANDIABANKEN {
  background-image: url("./bankLogos/skandia.svg");
  background-size: 100%;
}
.DANSKE_BANK {
  background-image: url("./bankLogos/danske_bank.svg");
  background-size: 100%;
}
.MARGINALEN {
  background-color: $bank-MARGINALEN;
  background-image: url("./bankLogos/marginalen.svg");
  background-size: 92%;
  background-position: center;
  @include media.tablet {
    background-size: 80%;
    background-position: center;
  }
}
.SPARBANKEN_SYD {
  background-color: $bank-SPARBANKEN_SYD;
  background-image: url("./bankLogos/SparbankenSyd.svg");
  background-size: 89%;
  background-position: center;
  @include media.tablet {
    background-size: 80%;
    background-position: center 52%;
  }
}
.SPARBANKERNA {
  background-color: $bank-SPARBANKERNA;
  background-image: url("./bankLogos/sparbankerna.png");
  background-size: 92%;
}

// DENMARK
.NEM_ACCOUNT {
  background-image: url("./bankLogos/nem_konto.svg");
  background-size: 100%;
}
