@use "~@lysaab/ui-2/components/styling/media";

.savings-summary-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  // Constrains animations within card
  position: relative;
  overflow: hidden;
  z-index: 0;

  @include media.tablet {
    padding: 24px 32px;
    flex-direction: row-reverse;
  }

  &__animations {
    z-index: -1;
  }

  &__information-button {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    margin-bottom: -8px;
  }

  &__numbers {
    display: grid;
    gap: 8px;

    @include media.tablet {
      gap: 24px;
    }

    @include media.desktop {
      grid-template-columns: 1fr auto;
      flex-grow: 2;
    }
  }
}
