@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.fee-tables {
  .select,
  .summary-table {
    margin-bottom: 3rem;
  }
  .saving-summary-table {
    margin-top: 2rem;
  }
}
