.readonly-marketing {
  padding: 1rem;

  > div {
    margin-bottom: 1rem;
  }

  div:last-child {
    margin-bottom: 0;
  }
}
