.UpdateWithdrawalPlan {
  .graph-wrapper {
    margin-bottom: 28px;
  }

  .withdrawal-duration-header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .repayment-disclaimer {
    margin-top: 28px;
  }

  .submit-button-container {
    margin-top: 40px;
  }
}
