@use "~@lysaab/ui-2/components/styling/variables";

.summary-account-card {
  .investment-type-info {
    margin-top: 8px;
  }

  .card-pane-list .card-pane-list--children .card-pane--list-item {
    .button-middle-text {
      text-align: center;
      font-weight: bold;
      padding: 8px 0;

      > span {
        color: variables.$color-red;
      }
    }

    .lysa-ui-button,
    .lysa-ui-input-group {
      + .horizon-container {
        margin-top: 40px;
      }
    }

    .lysa-ui-input-group {
      > label {
        display: none;
      }
      .lysa-ui-select {
        > select,
        > .lysa-ui-input-icon {
          display: none;
        }
      }
    }

    .column-row {
      flex-direction: column;
    }

    .item-title {
      font-weight: bold;
    }
  }

  .hidden-submit-button {
    display: none;
  }

  .lysa-ui-input-group:last-child {
    margin: 0;
  }
}
