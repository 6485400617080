@use "~@lysaab/ui-2/components/styling/variables";

.holding-declaration {
  page-break-before: auto;
  color: variables.$color-darkgray;
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  .declaration-list {
    border: 2px solid variables.$color-gray;
    padding: 0 12px 12px 12px;

    + .declaration-list {
      border-top: 0;
    }

    .text-container {
      page-break-inside: avoid;
      padding-top: 12px;

      .text-right {
        text-align: right;
        color: initial;
      }
    }
  }
}
