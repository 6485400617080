.invite-buttons-wrapper {
  margin-top: 32px;

  .lysa-ui-button {
    width: 100%;
    margin: 0 0 16px;
  }

  .invite-button-share,
  .invite-button-copy {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
}
