.transfer-pension-intro-group-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10rem;

  .icon {
    margin: 2rem;
  }

  .transfer-pension-intro-group-into-icon {
    margin: 2rem;
  }
}
