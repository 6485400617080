.edit-account-risk-confirm {
  dl {
    margin: 0;
  }

  .as-link {
    margin: 0 0 30px 0;

    a {
      text-align: left;
      line-height: 1.5;
    }
  }

  .risk-indicator {
    margin: 0 0 0 6px;
  }

  dt,
  dd {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin: 0 0 6px 0;
  }

  dt {
    font-weight: bold;
  }

  dd {
    text-align: right;

    span {
      text-transform: capitalize;
    }
  }
}
