@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.shared-savings-account-page {
  .shared-savings-account-page__header {
    margin-bottom: 4px;
  }

  &__subheader {
    display: flex;
    font-size: 1.2em;
    margin-bottom: 12px;
    align-items: center;

    .icon {
      font-size: 1.3em;
      color: variables.$color-blue;
      margin-right: 6px;
    }

    span {
      opacity: 0.7;
    }
  }

  &__grid {
    margin-top: 80px;
  }

  .account-page-card-body {
    padding: 17px 16px;

    @include media.landscape {
      padding: 17px 22px;
    }
  }
}
