.transfer-pension-document-card {
  /* Override default style */
  box-shadow: none !important;
  margin-bottom: 1rem;
  width: 100%;
  background-color: var(--color-gray-50);
  padding: 18px 24px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .body {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
