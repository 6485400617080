@use "~@lysaab/ui-2/components/styling/variables";

.account-page-transactions {
  .transaction-entry {
    display: flex;
    flex-wrap: nowrap;
    padding: 12px 0 0 0;

    &.last-entry {
      position: absolute;
      width: 100%;
    }

    & > div {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }

    .transaction-entry-header {
      font-size: 12px;
      line-height: 1.4;
      color: #6b6e71;
    }

    .transaction-entry-indicator {
      display: inline-block;
      margin: 0 4px 0 0;
      border-radius: 2px;
      width: 10px;
      height: 10px;
      vertical-align: middle;
    }

    &.transaction-entry-deposit {
      .transaction-entry-indicator {
        background-color: var(--lysa-indicator-deposit);
      }
    }

    &.transaction-entry-withdrawal {
      .transaction-entry-indicator {
        background-color: var(--lysa-indicator-withdrawal);
      }
    }
  }
}
