@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.card-pane-list {
  .card-pane-list--children {
    .card-pane--list-item {
      padding: 16px 0;

      + .card-pane--list-item {
        border-top: 1px solid variables.$color-gray;
      }
    }
  }
}
