@use "~@lysaab/ui-2/components/styling/media";

.pension-only-shortcuts-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include media.tablet {
    max-width: 100%;
  }
  @include media.desktop {
    max-width: 50%;
  }
}
