.account-page-share-account {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card .lysa-ui-snackbar {
    padding: 16px 24px 16px 24px;
    display: block;
    text-align: left;
  }

  ul {
    margin: 8px 0;
    // margin-top: 0;

    li {
      padding: 4px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .date {
      opacity: 0.7;
    }
  }
}
