.account-page-close-account {
  .card .lysa-ui-snackbar {
    padding: 16px 24px 16px 24px;
    margin-bottom: 0px;

    .account-page-close-account__snackbar {
      text-align: left;
    }
  }
}
