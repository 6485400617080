.fees-card {
  .fees-card__details-header {
    margin-bottom: 1rem;
  }
  .fees-card__details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}
