@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$bottom-margin: 8rem;
.savings-account-page {
  .savings-account-page__header {
    display: flex;

    .savings-account-page__header-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 0.4em;
    }

    .savings-account-page__header-type {
      opacity: 0.6;
      font-weight: 300;
    }
  }

  .savings-account-page__section-wrapper {
    margin-bottom: $bottom-margin;

    .savings-account-page__section {
      height: 100%;
      display: flex;
      flex-direction: column;

      .card {
        margin: 0;
      }

      .card-list {
        margin: 0;
        flex-grow: 1;
      }
    }
  }

  .account-page-card-body {
    padding: 17px 16px;

    @include media.landscape {
      padding: 17px 22px;
    }
  }
}
