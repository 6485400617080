.newsbanner {
  background-color: var(--lysa-info-background);
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 10px;

  p {
    margin-top: 0;
  }

  .newsbanner-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .newsbanner-close-button {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0 0 0 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}
