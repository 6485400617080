.card-button {
  padding: 0;
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;

  .card {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .start {
      display: flex;
      align-items: center;
      overflow: hidden;
      gap: 1rem;

      .label {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
      }

      .byline {
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        align-items: center;
        gap: 0.5rem;

        .icon {
          width: 1.6rem;
          min-width: 1.6rem;
          height: auto;
        }
      }
    }

    .end {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .heading {
    display: flex;
    gap: 0.8rem;
  }

  .dot {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    background-color: var(--lysa-brand-blue);
    border-radius: 50%;
    margin: 0.8rem 0;
  }

  .chevron-right {
    display: flex;
    align-items: center;
  }

  .ellipsis {
    white-space: nowrap;
    line-clamp: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
  }

  &:active {
    opacity: 0.5;
  }

  .flex-horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .flex-vertical {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
