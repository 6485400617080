@use "~@lysaab/ui-2/components/styling/variables";

.edit-allocation-page {
  .disclaimer {
    padding: 20px 12px;
  }

  .editallocation-header {
    display: flex;

    h3,
    h4 {
      flex: 1;
    }

    .button-small.reset-hidden {
      opacity: 0;
      transition: opacity variables.$timer-standard ease-in-out;
    }
  }

  .card-with-top-margin {
    margin-top: 38px;
  }

  .allocation-bar-value {
    font-weight: 600;
  }

  .allocation-bar-name {
    margin: 0 6px;
    opacity: 0.8;
    font-style: italic;
  }

  .allocation-slider-value-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
