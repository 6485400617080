.FlatButton {
  border-radius: var(--lysa-card-border-radius);
  background-color: var(--lysa-background-card-gray-box);
  padding: 16px 20px;
  width: 100%;
  border: none;

  &--clickable {
    cursor: pointer;
  }

  &__content {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    text-align: left;
    flex: 1;
  }

  &__value {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  & + & {
    margin-top: 16px;
  }
}
