.withdrawal-pension-age-and-length {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: calc(100vh - (64px + 64px * 2));

  .lysa-ui-input-group {
    margin-bottom: 1rem !important;
  }

  .chips {
    display: flex;
    gap: 1.4rem;
    margin-bottom: 2rem;

    .chip {
      padding: 0.2rem 1rem;
      border-radius: 16px;
      background-color: transparent;
      border: 1px solid var(--lysa-button-secondary-border);
    }

    .selected {
      background-color: black;
      color: white;
    }
  }

  .withdrawal-month-select-container {
    margin-bottom: 2rem;
  }
}
