.open-account {
  .open-account__icon-wrapper {
    display: flex;
    justify-content: center;

    .icon-primary {
      fill: var(--lysa-icon-secondary);
    }
  }
  .open-account__ingress {
    margin-bottom: 2.4rem;
  }

  .open-account__list {
    list-style: disc;
    padding-left: 2rem;

    li {
      margin-bottom: 1rem;
    }
  }
  .open-account__document-link {
    display: block;
    margin-bottom: 1rem;

    > button {
      text-decoration: none;
      text-align: left;
    }
  }
}
