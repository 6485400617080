@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.signature {
  width: 26px;
  height: 26px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &.customer {
    padding: 4px;
    color: var(--lysa-text-blue);
    background-color: var(--lysa-background-card-ligthblue-box);
  }

  &.admin {
    padding: 2px 4px;
    background-color: var(--lysa-background-card-gray-box);
  }
}
