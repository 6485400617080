@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.burger-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: var(--lysa-background-screen);
  justify-content: space-between;
  z-index: 999;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);

  .burger-menu-item-wrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include media.landscape {
      padding: 16px 24px;
    }
  }

  .burger-menu-item {
    display: flex;
    align-items: center;

    .lysa-ui-status-label {
      margin-bottom: 0;
    }
  }

  .burger-menu-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 32px 8px 0;
  }

  .burger-menu-item-text {
    overflow: hidden;
    white-space: nowrap;
    font-size: 18px;
    margin-right: 80px;
  }

  .burger-menu-burger {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1px 8px 8px 0;

    button {
      margin-right: 32px;
    }
  }

  hr {
    background-color: variables.$color-mediumgray;
    width: 100%;
    margin: 15px 0;
  }

  .in-menu-close {
    display: flex;
    align-items: center;
    margin-bottom: 39px;
  }

  .current-user {
    span {
      display: block;
      font-weight: 600;
      white-space: nowrap;
      line-height: 14px;
    }
    .current-user-label {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.transparent-button.floating-burger-menu {
  background-color: variables.$color-gray;
  padding: 12px;
  position: fixed;
  bottom: 57px;
  left: 24px;
  border-radius: 10px;
  z-index: 899;
}

.stripped-view {
  .burger-menu,
  .floating-burger-menu {
    display: none;
  }
}

._elevio_launcher {
  display: none;
}

.burger-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(variables.$color-white, 0.5);
  z-index: 999;
}
