.link-item {
  &:first-of-type {
    border-top: 1px solid var(--lysa-border-gray);
  }
  border-bottom: 1px solid var(--lysa-border-gray);

  &.link-item--selected {
    background-color: var(--lysa-border-gray);
  }

  .link-item__button {
    padding: 16px 6px;
    background: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    .link-item__col {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
    .link-item__trailing {
      align-self: center;
    }
    .link-item__trailing-icon {
      flex-shrink: 0;

      .icon-primary {
        fill: var(--lysa-icon-secondary);
      }
    }
  }
}
