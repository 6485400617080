.institution-allocation-card {
  .institution-allocation-card__header {
    font-weight: bold;
  }
  .institution-allocation-card__list-item {
    display: flex;
    justify-content: space-between;

    .institution-allocation-card__bold-term {
      font-weight: bold;
    }
  }

  dl {
    margin: 0;

    .card-list-item {
      border-bottom: 1px solid var(--lysa-border-gray);
      padding: 0 0 4px 0;
      margin-bottom: 12px;
    }
  }
}
