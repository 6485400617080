@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.transaction-card {
  .transaction-card-header {
    border-bottom: 2px solid variables.$color-gray;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.75rem;

    &-type {
      font-weight: bold;
    }
  }
  .transaction-card-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .transaction-card-row__indicator-wrapper {
      display: flex;
      align-items: center;
    }

    dd {
      margin: 0;
    }

    .account {
      font-weight: bold;
      margin-right: 1rem;
    }
    .amount {
      display: inline;
      margin-right: 0.5rem;
    }
  }
}
