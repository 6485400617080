@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.skeleton-thread.thread {
  .thread-button {
    position: relative;
    color: transparent;

    .all-but-date {
      .signature {
        color: transparent;
        background-color: variables.$color-secondary-disabled;
      }
    }

    .subject {
      padding-right: 6em;
    }

    .subject,
    .short-message,
    .date-time {
      background-color: variables.$color-secondary-disabled;
      border: 2px solid transparent;
      border-radius: 10px;
      line-height: 10px;
    }
  }

  .skeleton-animation-first,
  .skeleton-animation-second {
    background-color: white;
    position: absolute;
    height: 40%;
    width: 1px;
    box-shadow: 0 0 20px 20px white;
    animation: run 3s infinite;
    z-index: 100;
    opacity: 0.7;

    @include media.tablet {
      animation: run-tablet 3s infinite;
    }
  }

  .skeleton-animation-second {
    animation: run-with-delay 3s infinite 1.5s;

    @include media.tablet {
      animation: run-with-delay-tablet 3s infinite 1.5s;
    }
  }
}

@keyframes run {
  0% {
    transform: translateX(-5vw);
  }
  100% {
    transform: translateX(105vw);
  }
}

@keyframes run-with-delay {
  0% {
    transform: translateX(-5vw);
  }
  100% {
    transform: translateX(105vw);
  }
}

@keyframes run-tablet {
  0% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(430px);
  }
}

@keyframes run-with-delay-tablet {
  0% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(430px);
  }
}
