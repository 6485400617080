.share-account-invitation-card {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .date {
    font-size: 0.9em;
    opacity: 0.65;
    display: block;
    line-height: 1.2em;
  }
  .status-label {
    margin-bottom: 0;
    flex-shrink: 0;
  }
  .more-button {
    margin-top: 8px;
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  margin-bottom: 20px;
}
