@media print {
  .germany-tax-page {
    header,
    footer,
    .floating-burger-menu,
    .burger-menu {
      display: none !important;
    }

    .select-column,
    .tax-reporting-information {
      display: none;
    }
    .card {
      box-shadow: none;
      border: none;
      padding: 0;

      .button-primary {
        display: none;
      }
    }
  }
}
