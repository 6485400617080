.withdrawal-help {
  margin-top: 32px;

  .card-list {
    .card-list-item-wrapper {
      &.transparent-button-component {
        display: flex;
        width: 100%;
        text-align: left;
      }

      a {
        text-decoration: none;
        color: var(--lysa-text-primary);
      }

      &:not(:last-child) {
        border-bottom: var(--lysa-card-border-size) solid
          var(--lysa-opacity-black-15);
      }
      &:hover {
        background-color: var(--lysa-button-secondary-hover);
        border-color: transparent;
      }
      &:first-child:hover {
        border-radius: var(--lysa-card-border-radius)
          var(--lysa-card-border-radius) 0 0;
      }
      &:last-child:hover {
        border-radius: 0 0 var(--lysa-card-border-radius)
          var(--lysa-card-border-radius);
      }

      .card-list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .left-section {
          display: flex;
          align-items: center;
          gap: 16px;
        }

        .text-wrapper {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
