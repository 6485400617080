@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$button-gray: var(--lysa-background-canvas);

:root {
  --lysa-interval-switch-button-background: #{variables.$color-mediumgray};
}

.graph-card.show-overlay {
  .interval-switch {
    opacity: 0.4;
  }
}

.interval-switch {
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
  opacity: 1;
  transition: opacity 300ms ease;
  margin: 0 -16px;
  padding: 0 16px;

  @include media.tablet {
    margin: 0;
    padding: 0;
  }

  .interval-switch-slider {
    overflow: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      display: flex;
      flex-wrap: nowrap;
      padding: 5px 0;
      justify-content: flex-start;
    }
  }

  .interval-switch-prev,
  .interval-switch-next {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    line-height: 100%;
    min-width: 40px;
    padding: 0 0 0 5px;
    border: 0;
    background-color: transparent;
    outline: none;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 0%,
      $button-gray 40%
    );
    opacity: 0;
    transition: opacity 300ms ease;
    pointer-events: none;
    text-align: left;
    z-index: 20;

    @include media.tablet {
      display: none;
    }
  }

  .interval-switch-next {
    text-align: right;
    left: auto;
    right: 0;
    padding: 0 5px 0 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      $button-gray 40%
    );
  }

  &.show-left-chevron {
    .interval-switch-prev {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.show-right-chevron {
    .interval-switch-next {
      opacity: 1;
      pointer-events: all;
    }
  }

  .interval-button {
    border: 0;
    margin: 0 4px;
    padding: 0;
    flex: 1 0 auto;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &:first-child {
      margin: 0 4px 0 0;
    }

    @include media.tablet {
      margin: 0 6px;
      padding: 0;
      flex: 0 0 auto;

      &:first-child {
        margin: 0 6px 0 0;
      }
    }

    @include media.desktop {
      margin: 0 8px;
      padding: 0;
      flex: 0 0 auto;

      &:first-child {
        margin: 0 8px 0 0;
      }
    }

    svg {
      fill-opacity: 0.9;
      position: relative;
      top: -3px;
    }
  }
}

.interval-button {
  & > span {
    padding: 8px 10px 0 10px;
    min-width: 54px;
    height: 30px;
    border-radius: 4px;
    background-color: var(--lysa-interval-switch-button-background);
    transition: background-color 300ms ease, color 300ms ease;
    display: inline-block;

    &:hover {
      background-color: variables.$color-lightblue;
    }
  }

  &.active {
    & > span {
      background-color: variables.$color-lightblue;
      color: variables.$color-blue;
      font-weight: 500;

      svg {
        fill-opacity: 1;
      }
    }
  }
}
