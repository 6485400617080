@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$button-min-width: 83px;

.withdrawal-page-external-accounts {
  position: relative;
  margin: 0 0 16px 0;

  .external-account-item-wrapper {
    position: relative;

    &:last-child {
      margin-bottom: 16px;
    }
  }

  .external-account-item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;

    .external-account-item-logo-and-number {
      display: flex;
      align-items: center;
      padding: 0 10px 0 0;
      width: calc(100% - $button-min-width);
      gap: 16px;

      .logo {
        flex-shrink: 0;
      }
    }

    .external-account-item-bank-and-number {
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .external-account-item-button {
      min-width: $button-min-width;
    }
  }
}
