.situation-slider {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
  .situation-slider-text {
    display: flex;
    justify-content: space-between;

    span:first-child {
      font-weight: bold;
    }

    & + p {
      margin-top: 0;
    }
  }
}
