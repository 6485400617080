.transfer-pension-story-taken-risk-deviation {
  .header {
    margin-bottom: 3rem;
  }

  .glide-path {
    margin: 1rem 0 1rem 0;
  }

  .information {
    margin-top: 4rem;
  }

  .current-value {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
  }

  .slider-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .slider-scale {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -1rem;
    margin-bottom: 4rem;
  }
}
