.static-input {
  margin-bottom: 12px;

  &:not(.static-input--side-by-side) {
    .static-label {
      display: block;
      line-height: 20px;
      margin-bottom: 8px;
    }

    .static-value {
      display: block;
      line-height: 20px;
      font-weight: bold;
      font-size: 16px;
      padding: 4px 0 20px;
    }
  }

  &--side-by-side {
    display: flex;
    justify-content: space-between;

    .static-label,
    .static-value {
      line-height: 20px;
      display: inline-flex;
    }

    .static-value {
      font-weight: 500;
    }
  }
}
