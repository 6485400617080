.account-page-insurance-info {
  dl {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    &:not(:last-of-type) {
      margin-bottom: 50px;
    }
  }

  dt,
  dd {
    vertical-align: top;
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0 4px 0;
    margin: 0;
    flex: 0 0 auto;
  }

  dd {
    width: 100px;
    text-align: right;
  }

  dt {
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
