@use "sass:math";

.valentines {
  $heart-size: 16px;
  $heart-count: 20;
  $heart-color: #e318a6;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .heart-wrapper {
    position: absolute;
    bottom: -$heart-size * 2;
    z-index: 1;
    display: none;

    animation-name: heart-rise, heart-drift;
    animation-timing-function: ease-out, ease-in-out;
    animation-iteration-count: infinite, infinite;
    animation-play-state: running, running;

    @for $i from 1 through $heart-count {
      $duration: math.div((random(25) + 50), 10) + s;
      $delay: math.div(random(100), 10) + s;

      &:nth-child(#{$i}) {
        display: block;
        animation-delay: $delay, math.div(random(100), 10) + s;
        animation-duration: $duration, 6s;
        left: math.div(100%, $heart-count) * $i;

        .heart {
          animation: heart-grow $duration $delay ease-in infinite running;
        }
      }
    }
  }

  .heart {
    background-color: $heart-color;
    height: $heart-size;
    width: $heart-size;
    position: relative;
    transform: rotate(-45deg);
  }

  .heart:before,
  .heart:after {
    content: "";
    background-color: $heart-color;
    border-radius: 50%;
    height: $heart-size;
    width: $heart-size;
    position: absolute;
  }

  .heart:before {
    top: math.div(-$heart-size, 2);
    left: 0;
  }

  .heart:after {
    left: math.div($heart-size, 2);
    top: 0;
  }

  @keyframes heart-rise {
    0% {
      bottom: -$heart-size;
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      bottom: 100%;
    }
  }

  @keyframes heart-drift {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(30px);
    }
    75% {
      transform: translateX(-30px);
    }
  }

  @keyframes heart-grow {
    0% {
      transform: scale(0.5) rotate(-45deg);
    }
    50% {
      transform: scale(1) rotate(-45deg);
    }
    100% {
      transform: scale(1) rotate(-45deg);
    }
  }
}
