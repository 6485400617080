@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-page-fees-example {
  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0 4px 0;
    margin: 0;
    flex: 0 0 auto;
    width: 100%;
  }

  .lysa-ui-snackbar {
    padding: 12px 12px;
    font-size: 12px;
    line-height: 1.6;

    @include media.tablet {
      padding: 16px;
      font-size: 14px;
      line-height: 1.8;
    }
  }
}
