.transfer-pension-intro-faq {
  width: 100%;
  text-align: start;
  margin-bottom: 4rem;
  margin-top: 2rem;

  .faq-item {
    border-bottom: 1px solid #ddd;
  }

  .title {
    margin: 0 !important;
  }

  .faq-button {
    width: 100%;
    background: none;
    border: none;
    padding: 1rem;
    text-align: left;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    outline: none;
  }

  .chevron {
    transition: transform 0.3s ease;
  }

  .chevron-open {
    transform: rotate(-90deg);
  }

  .chevron-closed {
    transform: rotate(90deg);
  }

  .faq-content {
    padding: 0 1rem 1rem 1rem;
    margin: 0 !important;
  }
}
