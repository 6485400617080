.card-list-action-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__label {
      font-weight: 400;
    }

    &__text {
      font-size: 16px;
      font-weight: 600;
      word-break: break-word;
    }
  }

  &__action {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
