@import "~@lysaab/ui-2/components/styling/_variables";

.agreement-signer {
  display: flex;

  & + .agreement-signer {
    margin-top: 10px;
  }

  .name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    line-height: 32px;
  }
}
