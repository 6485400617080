@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.messages-page {
  .messages-page-grid {
    margin: 0 0 40px 0;

    @include media.tablet {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-rows: auto;
      column-gap: 50px;
      row-gap: 80px;
      align-items: stretch;
    }

    @include media.desktop-plus {
      column-gap: 100px;
    }
  }

  .skeleton-messages {
    opacity: 0;
  }

  .is-loading {
    .skeleton-messages {
      opacity: 1;
    }
  }
}
