.close-account {
  &-error {
    .lysa-ui-snackbar {
      margin-bottom: 0;
    }
  }
  &__row-wrapper {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--lysa-border-gray);

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
}
