.pension-withdrawal-info-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;

  margin-bottom: 3rem !important;

  .content {
    flex: 1;
  }
}
