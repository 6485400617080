@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-page-disclaimer {
  opacity: 0.5;
  font-size: 11px;
  line-height: 1.5;
  margin: 2rem 1.4rem 0 1.4rem;

  @include media.tablet {
    margin: 2rem 2.4rem 0 2.4rem;
    font-size: 13px;
  }
}
