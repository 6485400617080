.autogiro-deposit-done {
  .done-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    dd {
      div {
        display: inline;
      }
    }
  }
  dl {
    margin-bottom: 40px;
  }
}
