.update-account-page {
  h1 {
    margin: 0 0 50px 0;
  }

  .card {
    svg {
      margin: 0 auto;
      display: block;
    }
  }

  .update-account-page-summary-doc {
    margin: 40px 0 0 0;
  }
}
