@use "~@lysaab/ui-2/components/styling/media";

.transfers-menu-page {
  .lysa-ui-button {
    font-weight: 500;
    text-decoration: none;

    @include media.tablet {
      margin: 0;
      flex: 0 1 auto;
      min-width: calc(33.33% - 20px);
    }

    & + .lysa-ui-button {
      margin: 10px 0 0 0;

      @include media.tablet {
        margin: 0 0 20px 0;
      }
    }
  }

  .padding-box {
    @include media.tablet {
      min-width: calc(33.33% - 20px);
    }
  }

  .transfers-menu-page-body {
    @include media.tablet {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .transfers-menu-page-links {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 800px;

      @include media.tablet {
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-between;
        flex: 0 1 auto;

        & > span {
          margin: 0;
          flex: 0 1 auto;
          min-width: calc(33.33% - 20px);
        }
      }
    }
  }
}
