.open-account-done {
  .open-account-done__icon-wrapper {
    display: flex;
    justify-content: center;

    .icon-primary {
      fill: var(--lysa-icon-secondary);
    }
  }
  .open-account-done__list {
    margin-bottom: 3rem;

    .open-account-done__list-item {
      display: flex;
      margin-bottom: 1rem;

      svg {
        margin-right: 12px;
        flex-shrink: 0;

        .icon-primary {
          fill: var(--lysa-icon-secondary);
        }
      }
    }
  }
}
