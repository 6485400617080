.account-page-monthly {
  display: flex;
  flex-direction: column;
  height: 100%;

  .card-list {
    flex-grow: 1;
  }

  .retry-wrapper,
  .retry,
  .account-page-padder {
    height: 100%;
  }

  .account-page-padder {
    justify-content: space-between;
    display: flex;
    align-content: space-between;
    flex-direction: column;
  }

  .no-entries {
    padding: 0 20px 0 0;
  }
}
