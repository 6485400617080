@use "sass:math";
$grid-columns: 12;

@media print {
  .lysa-ui-col {
    @for $i from 1 through $grid-columns {
      &.col-print-#{$i} {
        width: percentage(math.div($i, $grid-columns));
      }
    }
    &.no-print {
      display: none;
    }
  }
}
