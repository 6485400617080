.transfer-pension-story-pension-settings {
  .heading {
    margin-bottom: 3rem;
  }

  .selectable-card {
    /* Override default SelectableCard style  */
    box-shadow: none !important;
    margin-bottom: 1rem;
    width: 100%;
    background-color: var(--color-gray-50);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .start {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .end {
      display: flex;
      align-items: center;
      gap: 0.6rem;

      p {
        margin: 0;
      }
    }
  }

  .snackbar {
    margin-top: 2rem;
  }

  .actions {
    margin-top: 3rem;
  }
}
