@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$height: 100px;
$transitionDuration: 500ms;

.write-messages {
  position: relative;

  .textarea-wrapper {
    transition: opacity $transitionDuration ease 1s;
    position: relative;
    opacity: 1;
  }

  .lysa-ui-snackbar {
    transition: opacity $transitionDuration ease;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 15;
    margin-bottom: 0;
    pointer-events: none;
  }

  &.has-error .lysa-snackbar-error {
    opacity: 1;
  }

  &.is-saved {
    pointer-events: none;

    .lysa-snackbar-success {
      transition: opacity $transitionDuration ease 1s;
      opacity: 1;
    }

    .textarea-wrapper {
      transition: opacity $transitionDuration ease;
      opacity: 0;
    }

    &.max-no-threads .lysa-snackbar-warning {
      opacity: 0;
    }
  }

  &.is-edit {
    &.max-no-threads {
      .lysa-snackbar-warning {
        opacity: 0;
        position: absolute;
      }
      textarea {
        display: block;
      }
    }
  }

  &.max-no-threads {
    pointer-events: none;

    .lysa-snackbar-warning {
      opacity: 1;
      position: relative;
    }

    .textarea-placeholder {
      opacity: 0;
    }

    textarea {
      display: none;
    }
  }

  textarea {
    min-height: $height;
    display: block;
    width: 100%;
    resize: none;
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    z-index: 10;
    position: relative;
    transition: opacity 300ms ease;
    opacity: 0;
    border: 2px solid variables.$color-blue;
    outline-color: variables.$color-blue;
  }

  .textarea-placeholder {
    border-radius: 5px;
    border: 1px dashed variables.$color-blue;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    display: flex;
    z-index: 5;
    color: variables.$color-blue;

    svg {
      transform: rotate(45deg);
      margin: 0 10px 0 0;
    }
  }

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .is-edit {
    textarea {
      opacity: 1;
    }
  }

  .write-message-buttons {
    flex-wrap: wrap;

    .send {
      flex-grow: 3;
      flex-basis: 60%;
    }
  }
}
