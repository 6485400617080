.fees-card {
  .fees-card__header {
    display: flex;
  }

  .fees-card__row {
    display: flex;
    justify-content: space-between;

    .fees-card__row-text {
      text-align: right;

      &--bold {
        font-weight: 500;
      }
    }
  }
}
