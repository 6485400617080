@use "~@lysaab/ui-2/components/styling/variables";

.share-account-invite-card {
  .sub-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .as-link {
    padding: 0;
  }

  hr {
    margin: 12px 0;
  }

  ul {
    margin-top: 4px;
    list-style: disc;
    margin-left: 18px;

    li {
      margin-bottom: 4px;
    }
  }

  .list-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 1em;
    }
  }

  .qr-code {
    display: flex;
    justify-content: center;
    margin-bottom: 36px;
    margin-top: 24px;

    canvas {
      width: 100%;
      height: auto;
      max-height: 160px;
      max-width: 160px;
      border: 3px solid variables.$color-black;
      padding: 0px;
      border-radius: 8px;
    }
  }

  .lysa-ui-snackbar {
    margin-bottom: 0;
  }

  .info-snackbar {
    margin-top: 12px;

    .lysa-ui-snackbar {
      padding: 16px;
      margin: 0;
    }
  }
}
