.tax-page {
  dt {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  p {
    margin-bottom: 15px;
  }
  .tax-print-button {
    display: flex;
    justify-content: space-around;
  }
}
