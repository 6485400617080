@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.overview-page-accounts-top {
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 0 0 55%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }

  .button-circle {
    flex: 0 0 32px;
    margin: 0 0 0 10px;
    padding: 5px;

    svg {
      transition: transform 300ms ease;
      transform: rotate(0deg);
      transform-origin: center;
      margin: -1px 0 0 0;
    }
    &.ascending {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .lysa-ui-input-group {
    position: relative;
    max-width: 180px;

    select {
      line-height: 32px;
      height: 32px;
    }
  }

  .lysa-ui-input-icon {
    top: 8px;
  }

  label {
    font-size: 12px;
    position: absolute;
    top: -20px;
    right: -42px;

    @include media.desktop {
      top: -26px;
      font-size: 14px;
    }
  }
}
