.create-savings-info {
  .create-savings-info__modal-link {
    margin-bottom: 16px;
  }
}

.create-savings-modal {
  .create-savings-modal__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .create-savings-modal__close {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0 0 0 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-self: flex-start;
  }

  .create-savings-modal__elevio-link {
    margin-bottom: 24px;
  }

  .create-savings-modal__disclaimer-header {
    font-size: 16px;
  }
}
