.sign-step-indicator {
  padding: 7px 12px;
  border-radius: 2rem;
  background-color: var(--color-gray-50);
  display: grid;
  align-items: center;
  margin: 0;

  p {
    margin: 0;
  }
}
