@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$file-size-width: 90px;

.write-messages,
.thread-actions {
  .attached-files {
    position: relative;
  }

  .messages-attached-files {
    min-height: 38px;
    font-size: 13px;
    position: relative;

    @include media.tablet {
      font-size: 14px;
      min-height: 48px;
    }

    .messages-attached-files-types {
      transition: opacity 300ms ease;
      font-size: 10px;
      line-height: 1.4;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      opacity: 0.7;

      @include media.landscape {
        font-size: 13px;
      }
    }
  }

  .has-attached-files {
    .messages-attached-files-types {
      opacity: 0;
    }

    .messages-attached-file-name {
      opacity: 1;
    }
  }

  .file-error {
    & > .messages-attached-file-name {
      opacity: 0;
    }
  }

  .messages-attached-file {
    position: relative;
    height: 28px;

    & > .messages-attached-file-name {
      font-weight: 600;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - #{$file-size-width});
      padding: 0 10px 0 0;
      display: inline-block;
      vertical-align: top;
      transition: opacity 300ms ease;
    }

    .file-error-elem {
      transition: opacity 300ms ease;
      opacity: 0;
      width: 100%;
      display: inline-block;
      vertical-align: top;
    }

    .file-done-elem,
    .file-pending-elem {
      transition: opacity 300ms ease;
      opacity: 0;
      text-align: right;
      width: $file-size-width;
      display: inline-block;
      vertical-align: top;
    }

    .file-done-elem {
      button {
        margin: -2px 0 0 6px;
        vertical-align: middle;
        min-width: 0;
      }
    }

    .file-error-elem {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      color: #ff7576;
    }

    .file-pending-elem {
      pointer-events: none;
      height: 4px;
      left: 130px;
      top: 9px;
      position: absolute;
      transition: opacity 300ms ease;
      opacity: 0;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;

      .file-pending-elem-bg {
        background-color: variables.$color-lightgray;
        height: 100%;
        width: 100%;
        border-radius: 2px;
      }

      .file-pending-elem-progress {
        background-color: variables.$color-black;
        height: 100%;
        width: 100%;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 0;
        transition: transform 300ms ease;
      }

      @include media.tablet {
        width: calc(100% - 180px);
        left: 180px;
      }
    }

    &.file-pending {
      .file-pending-elem {
        opacity: 1;
      }
    }

    &.file-done {
      .file-done-elem {
        opacity: 1;
      }
    }

    &.file-error {
      .file-error-elem {
        opacity: 1;
      }
    }
  }

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &:hover + .label-file-input {
      background-color: #cccccc;
    }

    &:focus + .label-file-input {
      border: solid 2px #0b1f71;
    }

    &:disabled + .label-file-input {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .messages-attached-files {
    flex-basis: 100%;

    &.has-attached-files {
      .messages-attached-files-types {
        max-height: 0;
      }
    }
  }
}

.write-messages {
  .label-file-input {
    margin: 0 0 0 20px;
  }
}

.messages-attached-files {
  margin: 10px 0 0 0;

  @include media.tablet {
    margin: 20px 0 0 0;
  }
}

.thread-actions {
  .label-file-input {
    margin: 0 0 0 20px;
  }

  .messages-attached-files {
    &.label-file-input-2 {
      margin: 0 0 10px 0;
    }

    &.label-file-input-3 {
      margin: 0 0 10px 0;
    }
  }
}
