.UpdateTakenRiskDeviation {
  .glide-path-container {
    margin-bottom: 28px;
  }
  .slider-scale {
    display: flex;
    justify-content: space-between;
  }

  .new-value {
    text-align: right;
  }

  .buttons-container {
    margin-top: 40px;
  }
}
