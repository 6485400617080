.create-account-corporation-candidate {
  .lysa-ui-radio {
    margin: 0 0 20px 0;
  }

  .repeat-country {
    margin: 0 0 30px 0;

    .lysa-ui-input-group {
      margin-bottom: 6px;
    }
  }

  .create-account-corporation-candidate-top {
    h1 {
      margin: 0 0 14px 0;
    }

    .candidate-subtitle {
      margin: 0 0 40px 0;
    }
  }
}
