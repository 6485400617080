.store-page {
  .flex {
    margin: 10px 0;
    align-items: center;
  }

  .button-primary {
    margin: 0 0 0 20px;
  }
}
