@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.show-more-button {
  &.lysa-ui-button {
    align-items: center;
    width: auto;
    min-width: 95px;
  }

  &.lysa-ui-button.button-small:not(.block) {
    width: auto;
  }

  .rotate {
    svg {
      transition: transform 500ms;
    }
    &--down {
      svg {
        transform: rotateX(180deg);
      }
    }
  }
}
