@use "~@lysaab/ui-2/components/styling/variables";

.deposits-recommendation {
  .deposit-recommendation-content {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 25px;
  }

  .card-list {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  a.card-list-item {
    display: block;
  }

  .deposit-description {
    color: variables.$color-darkgray;
    font-size: 12px;
    margin-bottom: 4px;
  }

  .deposit-name {
    color: variables.$color-black;
    font-weight: bold;
  }

  .deposit-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      max-width: 60px;
      max-height: 60px;
    }

    .icon-wrapper {
      background-color: variables.$color-lightgray;
      border-radius: 50%;
      padding: 12px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .lysa-ui-status-label {
    margin-right: 16px;
  }
}
