@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$height: 100px;

.thread-actions {
  position: relative;

  .close-button {
    margin-bottom: 20px;
  }

  .from-customer {
    .message-body {
      min-height: $height;
    }
  }

  .thread-actions-body {
    transition: opacity 300ms ease;
    opacity: 1;
  }

  .textarea-wrapper {
    position: relative;
    margin: 0 30px 10px 50px;

    @include media.tablet {
      margin: 0 30px 20px 70px;
    }

    @include media.desktop-plus {
      margin: 0 36px 20px 100px;
    }
  }

  textarea {
    min-height: $height;
    display: block;
    width: 100%;
    resize: none;
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    z-index: 10;
    position: relative;
    transition: opacity 300ms ease;
    opacity: 0;
    border: 2px solid variables.$color-blue;
  }

  .textarea-placeholder {
    border-radius: 5px;
    border: 1px dashed #adadeb;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
    display: flex;
    z-index: 5;
    color: variables.$color-blue;

    svg {
      transform: rotate(45deg);
      margin: 0 10px 0 0;
    }
  }

  .reply-icon {
    align-items: center;
    display: flex;
    position: absolute;

    span {
      margin-top: 1px;
    }
  }

  &.is-edit {
    textarea {
      opacity: 1;
    }
  }

  .message-error {
    pointer-events: none;
    transition: opacity 300ms ease;
    opacity: 0;
    z-index: 15;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
  }

  &.has-error {
    .thread-actions-body {
      opacity: 0.2;
      pointer-events: none;
    }
    .message-error {
      opacity: 1;
    }
  }

  .thread-actions-buttons {
    flex-wrap: wrap;

    .send {
      flex-grow: 3;
      flex-basis: 60%;
      margin-bottom: 1rem;
    }
  }

  .internal-close-info {
    margin: 16px 16px 0 16px;
    text-align: center;
    font-size: 12px;
  }
}
