.withdrawal-pension-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  min-height: calc(100vh - (64px + 64px * 2));

  .ingress {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--lysa-text-secondary);
    margin-top: -0.2rem;
    padding: 0 4rem;
  }

  .time-estimate {
    padding-top: 0.8rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    color: var(--lysa-text-secondary);
  }
}
