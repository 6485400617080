@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.mandates {
  .mandates__print-link {
    button {
      text-decoration: none;
    }
  }
  /* Override button-styling */
  .lysa-ui-button.button-small:not(.block) {
    width: 10rem;
  }
}
