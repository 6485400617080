@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.change-email-page {
  .content-wrapper {
    max-width: 500px;
  }

  .send-error {
    color: variables.$color-red;
  }
}
