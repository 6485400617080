.virtual-account-information-page {
  .info-texts {
    margin-bottom: 38px;
  }

  .more-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 38px;

    .more-button {
      width: auto;
    }
  }
}
