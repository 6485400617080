@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.withdrawal-request-page-kf-signing-pending {
  padding: 0 0 50px 0;

  .additional-actions,
  .expire-warning {
    display: none;
  }

  &.multiple-signees {
    .additional-actions,
    .expire-warning {
      display: block;
    }
  }

  .signing-list {
    margin: 0 0 30px 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 14px 0;
    }
  }

  .email-wrapper {
    margin: 40px 0 0 0;
    position: relative;
  }

  .additional-actions {
    .lysa-ui-snackbar {
      width: 100%;
      position: absolute;
      top: 0;
      margin: 0;
      pointer-events: none;
      z-index: 10;

      b {
        margin: 0 6px 0 0;
      }
    }

    .signers-copy {
      position: relative;

      .lysa-ui-snackbar {
        top: auto;
        bottom: 0;
      }
    }
  }
}
