@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.change-password-page {
  .content-wrapper {
    max-width: 500px;
  }

  .lysa-ui-input-group:last-of-type {
    margin-bottom: 7px;
  }
}
