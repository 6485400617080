.PensionAccountFocus {
  &__info-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px;
    margin: 20px 0;
    border-radius: var(--lysa-card-border-radius);
    background-color: var(--lysa-background-card-gray-box);
  }

  dl {
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 16px;

    dt,
    dd {
      margin: 0;
      width: auto;
    }
  }

  .repayment-information-dt {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    gap: 8px;
    align-items: center;

    .repayment-information-button {
      border: none;
      min-height: 0;
      height: 18px;
      width: 18px;
    }
  }
}
