.fees-disclaimer {
  margin-top: 2rem;
  opacity: 0.8;
  padding: 0 2rem;

  .list {
    list-style-type: disc;
    margin-left: 1.4rem;
  }
}
