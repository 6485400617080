.savings-account-tax-details {
  .savings-account-tax-details__section {
    margin-bottom: 40px;

    .savings-account-tax-details__section--right {
      text-align: right;
    }
  }
  .savings-account-tax-details__account-row {
    display: flex;
    justify-content: space-between;
    margin: 0 0 1.5rem 0;
  }

  .savings-account-tax-details__account-row--bold {
    font-weight: bold;
  }
  .savings-account-tax-details__account-row-divider {
    padding-bottom: 4px;
    border-bottom: 1px solid var(--lysa-border-gray);
    margin-bottom: 0.5rem;
  }

  .savings-account-tax-details__print-button {
    display: flex;
    justify-content: space-around;
  }
}
