@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.current-crs-information {
  dl {
    margin-bottom: 3rem;

    .list-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    dt {
      font-weight: bold;
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    background-color: variables.$color-gray;
    margin-bottom: 1rem;
  }
}
