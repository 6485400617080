.account-page-change-name {
  .card {
    position: relative;
  }

  .account-page-change-name-pending,
  .account-page-change-name-saved,
  .account-page-change-name-error {
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 300ms ease;
    pointer-events: none;
    top: 0;
    left: 0;
    border-radius: 10px;
    padding: 15px 22px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  &.is-pending {
    .account-page-change-name-pending {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.was-saved {
    .account-page-change-name-saved {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.has-error {
    .account-page-change-name-error {
      opacity: 1;
      pointer-events: all;
    }
  }
}
