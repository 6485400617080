.fees-information-card {
  margin-top: 38px;

  .slider-header {
    display: flex;
    justify-content: space-between;
  }

  .feeRows {
    .feeRow {
      display: flex;
      margin-bottom: 1rem;

      .label {
        flex: 1;

        .explanation {
          margin: 0.3rem 0 0 0;
        }
      }

      .value {
        width: 12rem;
        text-align: right;
      }
    }
  }

  .feeExplanation {
    margin-top: 16px;
  }
}
