@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.overview-page-accounts {
  margin: 70px 0;

  @include media.desktop {
    margin: 80px 0;
  }

  .card {
    position: relative;
    overflow: hidden;
  }

  .overview-page-account-link {
    color: var(--lysa-text-primary);
    transition: color 300ms ease;
    display: block;

    &:visited {
      color: var(--lysa-text-primary);
    }

    &:active,
    &:hover,
    &:focus {
      color: var(--lysa-text-primary);
    }
  }

  .overview-account-inner {
    position: relative;
    z-index: 2;
  }

  @include media.tablet {
    .overview-page-accounts-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .overview-account,
  .overview-savings-account {
    @include media.tablet {
      width: calc(50% - 20px);
    }

    @include media.desktop {
      width: calc(33.33% - 20px);
    }

    @include media.desktop-plus {
      width: calc(33.33% - 40px);
    }
  }

  .appendix {
    @include media.desktop {
      &:after {
        content: "";
        width: calc(33.33% - 20px);
      }
    }

    @include media.desktop-plus {
      &:after {
        content: "";
        width: calc(33.33% - 40px);
      }
    }
  }
}

.overview-account,
.overview-savings-account {
  color: var(--lysa-text-primary);
  text-decoration: none;

  @include media.desktop {
    font-size: 1.6rem;
  }

  .card {
    padding: 10px;

    @include media.tablet {
      padding: 20px 24px;
    }
  }

  .overview-account-allocation {
    font-size: 13px;
    opacity: 0.7;
  }

  .overview-account-change-and-profits {
    display: flex;
    justify-content: space-between;

    .overview-account-profits {
      flex: 0 0 105px;
      text-align: right;

      @include media.desktop {
        flex: 0 0 115px;
      }
    }

    .overview-account-change {
      flex: 0 0 70px;
      text-align: right;
      padding: 0 5px;
    }

    .overview-account-dates {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .overview-account-button {
    .button-primary {
      white-space: nowrap;
    }
  }

  .account-name {
    min-width: 0;
    flex: 0 1 auto;
    margin: 0 10px 0 0;
    font-size: 1.8rem;
  }

  .overview-account-value {
    flex: 1 0 auto;
    text-align: right;
  }

  .overview-account-type-and-value {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 4px 0 0 0;

    @include media.tablet {
      margin: 6px 0 0 0;
    }

    & > span {
      opacity: 0.7;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 14px 0 0;
    }
  }

  .overview-account-dates {
    opacity: 0.7;
  }
}

.overview-page-accounts {
  .overview-page-accounts-list {
    @include media.tablet {
      .open-new-account {
        display: none;
      }

      &.single-account {
        .open-new-account {
          display: block;
        }
      }
    }
  }
}
