@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

@use "../InvoicePage.scss";

.trade-table {
  thead {
    tr {
      border-bottom: InvoicePage.$invoice-table-border;
      background-color: variables.$color-lightgray;
      color: variables.$color-black;

      th {
        background-color: variables.$color-white;
        color: variables.$color-black;
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: InvoicePage.$invoice-table-border;
        padding: InvoicePage.$invoice-table-padding;

        @include media.desktop {
          padding: 0;
          border-bottom: none;
        }
      }

      td:last-child {
        border-bottom: none;
      }

      td:first-child {
        margin-top: 0;
      }
    }
  }

  &--text-left {
    text-align: left;
  }

  /* Ensure always desktop-table will be printed */
  @media print {
    & {
      display: table;

      thead {
        display: table-header-group;
        border-bottom: InvoicePage.$invoice-table-border;

        th {
          padding: InvoicePage.$invoice-table-padding;
        }
      }

      tr {
        border-bottom: none;
      }

      td {
        display: table-cell;
        padding: InvoicePage.$invoice-table-padding;
        border: none;
      }

      td:before {
        content: "";
      }
    }
  }
}
