@use "~@lysaab/ui-2/components/styling/media";

.documents-page {
  display: flex;
  flex-direction: column-reverse;

  ul,
  ol {
    list-style-type: disc;
    margin: 0 0 0 16px;

    @include media.tablet {
      margin: 0 0 0 40px;
    }

    li {
      margin: 0 0 10px 0;
    }
  }

  .menu-wrapper {
    margin-top: 36px;
  }

  @include media.tablet {
    flex-direction: row;
    .menu-wrapper {
      width: 25%;
      margin-top: 0;
      padding: 0 20px;
    }
    .document-wrapper {
      width: 75%;
    }

    .document-mobile-link {
      display: none;
    }
  }
}

.document-mobile-link {
  margin-bottom: 24px;
  @include media.tablet {
    display: none;
  }
}
