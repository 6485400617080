@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

$row-height: 4.5rem;
$table-side-padding: 1rem;

.pending-deposit-lysa-table {
  width: 100%;
  margin-bottom: 1rem;

  @include media.tablet {
    display: table;

    tr {
      display: table-row;
    }
    hr {
      display: none;
    }
  }

  // @media (max-width: variables.$tablet) {
  thead {
    display: none;
  }

  td {
    display: flex;
    justify-content: space-between;
    text-align: right;
    padding: 10px 0;
  }

  td:first-child {
    margin-top: 2rem;
  }

  td:before {
    content: attr(data-label);
    font-weight: bold;
    text-align: left;
    margin-right: 10px;
  }
  // }
}
