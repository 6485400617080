.GlidePathGraph {
  .svg {
    display: block;
    cursor: pointer;
  }

  .reallocation-line {
    stroke: var(--lysa-background-info);
  }

  .graph-background {
    fill: var(--lysa-background-info);
  }

  .graph {
    fill: var(--lysa-indicator-stocks);
  }

  .footer {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dot {
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.8rem;

    &.stocks {
      background-color: var(--lysa-indicator-stocks);
    }

    &.bonds {
      background-color: var(--lysa-background-info);
    }
  }
}
