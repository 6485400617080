@use "~@lysaab/ui-2/components/styling/variables";

.impersonation-bar {
  background-color: variables.$color-red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  color: variables.$color-white;

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;

    .lysa-ui-input-group {
      margin-bottom: 0;
    }

    .impersonated-user {
      font-size: 18px;
    }
  }

  @media print {
    display: none;
  }
}
