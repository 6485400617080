.deposit-expandable-content {
  background-color: var(--lysa-background-card-gray-box);
  padding: 8px;
  margin-top: 12px;
  margin-bottom: 4px;
  border-radius: 4px;
  font-size: 12px;

  li {
    list-style: disc;
    margin: 8px 0 8px 22px;

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
