@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-account-focus {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media.tablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .card-list {
    flex-grow: 1;
  }

  .account-page-padder {
    height: 100%;
    justify-content: space-between;
    display: flex;
    align-content: space-between;
    flex-direction: column;
  }

  dl {
    margin-top: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 16px;

    dt,
    dd {
      margin: 0;
      width: auto;
    }
  }

  .kf-info {
    *:last-child {
      margin-bottom: 0;
    }
  }
}
