@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

$card-side-padding: 16px;

.interval-switches {
  background-color: var(--lysa-background-canvas);
  padding: 5px $card-side-padding 20px $card-side-padding;

  @include media.landscape {
    padding: 10px $card-side-padding 20px $card-side-padding;
  }

  @include media.tablet {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  @include media.desktop {
    padding: 6px $card-side-padding 10px $card-side-padding;
    flex-wrap: wrap;
  }

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }

  .interval-switch {
    @include media.desktop {
      min-width: 0;
      margin-bottom: 10px;
      flex: 0 0 auto;
      order: 1;
      display: flex;
    }

    @media (min-width: 1200px) {
      margin: 16px 0 10px 0;
    }
  }

  .events-switch {
    margin-top: 14px;

    @include media.tablet {
      order: 2;
      flex: 0 0 auto;
      margin: 0;
    }

    @media (min-width: 1200px) {
      order: 3;
    }
  }

  .interval-custom-dates {
    @include media.tablet {
      order: 3;
    }

    @media (min-width: 1200px) {
      order: 2;
      flex: 1 0 300px;
      display: flex;
      align-content: flex-start;
      margin-left: 40px;
    }
  }
}
