@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";
@use "../components/styling/layout";

.page {
  padding: 0 16px;
  position: relative;

  @include media.landscape {
    padding: 0 24px;
  }

  @include media.desktop {
    padding: 0 24px #{layout.$footer-height-desktop} 24px;
  }

  & > article {
    margin: 0 auto;
    max-width: variables.$desktop-plus;
    min-height: calc(
      100vh - #{layout.$header-height + layout.$footer-height-desktop}
    );
    padding: 0 0 40px 0;

    & > .page-header {
      padding: 20px 0;
    }
  }

  &.page-stripped {
    padding: 0;

    > article {
      padding: 0;
    }
  }
}
