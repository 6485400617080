.close-account-confirmation {
  &-rows {
    margin-bottom: 3rem;

    > div {
      margin-bottom: 1rem;
    }

    div:last-child {
      margin-bottom: 0;
    }
  }
  &-error {
    .lysa-ui-snackbar {
      margin-bottom: 0;
    }
  }
}
