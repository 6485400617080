@use "~@lysaab/ui-2/components/styling/variables";

.account-list {
  margin-top: 50px;
  color: variables.$color-darkgray;

  .account-list-item {
    margin: 12px 0;

    .account-list-item-title {
      text-transform: uppercase;
    }

    .account-list-item-date {
      font-size: 12px;
    }

    & > ul > li {
      margin: 4px 0;
    }
  }
}
