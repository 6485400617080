@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.ReactModal__Overlay .tink-modal {
  @media (max-width: variables.$tablet) {
    /*
    * Since the Modal component uses the style attribute directly for these rules
    * they become very specific. We need to override them with !important for them to take effect.
    * If we move the rules in the Modal component to the CSS-class definition instead we could probably drop these,
    * they are just static values anyway.
    */
    /*
    * vw might be good enough, but dvw should be better.
    * vw is a good fallback.
    */
    @supports (width: 100dvw) {
      width: 100dvw !important;
    }
    width: 100vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0 !important;
  }

  /*
  * Same issue with use of style attribute for specificity as in the media query above.
  */
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .tink-modal__iframe {
    @supports (height: 100dvh) {
      height: 100dvh;
    }
    /*
    * vh is not optimal because it does not take mobile toolbars into consideration,
    * but good enough fallback. dvh support is wide now.
    */
    height: 100vh;

    /*
    * Since the Continue-button in Tink is fixated at the bottom it looks a bit
    * quirky when the modal is too tall. We can limit the height of the modal.
    */
    max-height: 900px;
    /*
    * iframe is an inline element, allocating extra space below for the fonts depth under the baseline.
    * We can avoid this space by setting it to a block.
    */
    display: block;
    width: 100%;
    border: none;
  }

  .modal-body {
    height: 100%;
    max-height: 100%;
    padding: 0;
    overflow-y: hidden;
  }

  .modal-body-scroll-top-button {
    display: none;
  }
}
