.not-found-page {
  article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 560px;
  }
  img {
    width: 100%;
    max-width: 480px;
    margin-bottom: 20px;
  }
}
