.transfer-pension-manual-input-group {
  /* Override margin-bottom from ui-2 */
  .lysa-ui-input-group {
    margin-bottom: 0;
  }
  /* Add back the margin-bottom after last element in the group */
  .input-info {
    margin-top: 0.4rem;
    margin-bottom: 24px;
    color: var(--lysa-text-secondary);
  }
}
