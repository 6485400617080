.transfer-pension-fees {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .ingress {
    width: 96%;
  }

  dl {
    margin-top: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 1px solid var(--lysa-card-border);
    padding-bottom: 0.6rem;

    dt,
    dd {
      margin: 0;
      width: auto;
    }

    dd {
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }

  dl:last-of-type {
    border-bottom: none;
  }

  .total {
    font-weight: bold;
  }
}
