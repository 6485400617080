.transfer-pension-not-movable {
  .move-cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .button {
    margin-top: 2rem;
  }
}

.read-more-collective-footer {
  background-color: var(--color-gray-50);
  padding: 2rem;
  border-radius: 1rem;

  p {
    margin: 0 !important;
    padding: 0 !important;
  }
}
