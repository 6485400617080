.savings-interest-card__interest-card {
  .savings-interest-card__row-wrapper {
    margin-bottom: 20px;

    .savings-interest-card__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      .savings-interest-card__row-text {
        text-align: right;

        &--bold {
          font-weight: 500;
        }
      }
    }
  }
  .savings-interest-card___elevio-link-wrapper {
    margin-bottom: 20px;
  }
}
