@use "../../components/styling/layout.scss";
@use "~@lysaab/ui-2/components/styling/media";

.new-customer-greeting {
  background-color: transparent;
  margin: 40px 0 80px 0;

  .lysa-ui-button {
    margin-top: 40px;
  }

  .lysa-ui-button + .lysa-ui-button {
    margin: 0;
    margin-top: 12px;
  }

  @include media.tablet {
    margin: 140px 0 180px 0;

    .lysa-ui-button + .lysa-ui-button {
      margin: 0;
      margin-left: 12px;
    }
  }
}
