@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.yearly-review-info-modal {
  .ReactModal__Content {
    max-width: 90%;

    @include media.tablet {
      max-width: 40%;
    }

    @include media.desktop {
      max-width: 35%;
    }

    @include media.desktop-plus {
      max-width: 30%;
    }
  }
}

.yearly-review-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
