@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.overview-savings-account {
  @include media.desktop {
    font-size: 1.6rem;
  }

  .card--padding {
    padding: 10px;

    @include media.tablet {
      padding: 20px 24px;
    }
  }

  .button-primary {
    white-space: nowrap;
  }

  .overview-savings-account__interest {
    font-size: 13px;
    opacity: 0.7;
    display: block;
  }

  .overview-account-change-and-profits {
    display: flex;
    justify-content: space-between;

    .overview-account-profits {
      flex: 0 0 105px;
      text-align: right;

      @include media.desktop {
        flex: 0 0 115px;
      }
    }

    .overview-account-change {
      flex: 0 0 70px;
      text-align: right;
      padding: 0 5px;
    }

    .overview-account-dates {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .overview-account-value {
    flex: 1 0 auto;
    text-align: right;
  }

  .overview-account-type-and-value {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 4px 0 0 0;

    @include media.tablet {
      margin: 6px 0 0 0;
    }

    & > span {
      opacity: 0.7;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 14px 0 0;
    }
  }

  .overview-account-dates {
    opacity: 0.7;
  }
}
