.actionable-pensions-movable-insurance-fieldset {
  border: none;
  padding: 0;
  margin: 0;

  .move-cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .move-cards-header {
    margin: 2rem 0 0 0;
  }

  .move-cards-ingress {
    margin-bottom: 0;
  }

  .error-container {
    margin-top: 24px;
  }
}
