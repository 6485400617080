@use "~@lysaab/ui-2/components/styling/variables";

.virtual-account-deposits-story {
  .deposit-account-number {
    background-color: variables.$color-mediumgray;
    padding: 8px 16px;
    margin-top: 0px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
  }
}
