.monthly-overview {
  dl {
    dd {
      margin-inline-start: 0;
      text-align: right;
    }
  }

  .monthly-overview-section {
    margin-bottom: 40px;
  }

  .static-input {
    margin-bottom: 12px;
    .static-label {
      display: block;
    }

    .static-value {
      display: block;
      font-weight: bold;
    }
  }
}
