@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.card-button {
  padding: 0;
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;

  .card {
    margin: 0;
  }

  .ellipsis {
    white-space: nowrap;
    line-clamp: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:active {
    opacity: 0.5;
  }

  .flex-horizontal {
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 16px;
  }
}
