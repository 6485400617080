.FeesModal {
  dl {
    display: flex;
    gap: 4px;
  }

  dd {
    margin: 0;
    display: flex;
    gap: 4px;
    flex: 1;
    justify-content: space-between;
  }

  .total {
    font-weight: bold;
  }
}
