@use "~@lysaab/ui-2/components/styling/variables";

.account-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  strong {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding: 0 0.2em 0 0;
  }

  & > div {
    min-width: 0;
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .account-name-type {
    opacity: 0.6;
    margin: 0 0 0 0.2em;
    font-weight: 300;
  }

  .account-name-shared {
    font-size: 1.2em;
    margin: 0 0.4em 0 0;
    color: variables.$color-blue;
    flex-shrink: 0;
  }

  .risk-indicator {
    margin: 0 0 0 4px;
  }

  .account-name-savings-account {
    margin-left: 4px;

    .icon-primary {
      fill: var(--lysa-icon-secondary);
    }
  }
}
