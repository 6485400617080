.isk-transfer {
  ul {
    margin-left: 14px;
  }
  li {
    svg {
      margin-right: 14px;
      height: 20px;
    }
    align-items: center;
    display: flex;

    div:first-child {
      display: flex;
    }
    margin-bottom: 10px;
  }
  .confirm-table {
    td b {
      margin-right: 24px;
    }
  }

  .history-item {
    display: flex;
    justify-content: space-between;

    .badge-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .status-badge {
    color: var(--lysa-text-primary);
    border-radius: 25px;
    padding: 4px 8px;
    height: 32px;
    display: flex;
    align-items: center;
    font-weight: bold;

    &.pending {
      background-color: var(--lysa-background-warning);
      border: 3px solid var(--lysa-background-warning);
    }

    &.completed {
      background-color: var(--lysa-background-success);
      border: 3px solid var(--lysa-background-success);
    }
  }
}
