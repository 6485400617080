.change {
  font-weight: 500;

  .change-prefix {
    margin: 0 0.2em 0 0;
  }

  .change-entity {
    opacity: 0.7;
    font-size: 0.8em;
    margin: 0 0 0 0.3em;
    font-weight: normal;
  }
}
