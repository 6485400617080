.account-page-monthly-internal-transfers {
  display: flex;
  flex-direction: column;
  height: 100%;

  .card-list {
    flex-grow: 1;
  }

  .retry-wrapper,
  .retry,
  .account-page-padder {
    height: 100%;
  }

  .account-page-padder {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .no-entries {
    padding: 17px 22px;
  }
}
