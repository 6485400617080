@use "./components/styling/layout";
@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/layout" as *;

:root {
  --lysa-input-background: #{variables.$color-lightgray};
  --lysa-ui-legend: #{variables.$color-darkgray};
  --lysa-checkbox-background: #{variables.$color-mediumgray};
  --lysa-checkbox-checked-background: #{variables.$color-lightblue};
  --lysa-success-background: #{variables.$color-lightgreen};
  /**
   * We use a custom color here because variables.$color-lightblue is too
   * white. Value and comment copied from Snackbar.scss in ui-2
   */
  --lysa-info-background: #dfe2f7;
  --lysa-warning-background: #{variables.$color-lightyellow};
  --lysa-error-background: #{variables.$color-lightred};
  --lysa-card-box-shadow: 0 10px 30px 0 rgb(0 0 0 / 10%);
  --lysa-forecast-tooltip-arrow-left-border-color: rgba(255, 255, 255, 0)
    rgb(255, 255, 255) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  --lysa-forecast-tooltip-arrow-right-border-color: rgba(255, 255, 255, 0)
    rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);
  --lysa-forecast-tooltip-arrow-border-left-border-color: rgba(255, 255, 255, 0)
    rgb(123, 137, 148) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  --lysa-forecast-tooltip-arrow-border-right-border-color: rgba(
      255,
      255,
      255,
      0
    )
    rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(123, 137, 148);

  font-size: 62.5%;
}

[data-theme="dark"] {
  --lysa-text-primary: var(--color-gray-50);
  --lysa-text-secondary: var(--color-gray-200);
  --lysa-link-hover: var(--color-blue-gray-400);

  --lysa-button-secondary-border: var(--color-gray-200);
  --lysa-button-secondary-text: var(--color-gray-200);
  --lysa-button-secondary-hover-border: var(--color-gray-200);
  --lysa-button-secondary-hover-text: var(--color-gray-200);
  --lysa-button-secondary-disabled-text: var(--color-gray-200);
  --lysa-button-secondary-disabled-border: var(--color-gray-200);

  --lysa-button-outlined-text: var(--color-gray-50);
  --lysa-button-outlined-hover-text: var(--color-gray-50);
  --lysa-button-outlined-disabled-text: var(--color-gray-50);

  --lysa-icon-primary: var(--color-gray-50);

  --lysa-background-canvas: var(--color-gray-900);
  --lysa-background-screen: var(--color-black);
  --lysa-background-card: var(--color-black);
  --lysa-background-card-bottom: var(--color-gray-900);
  --lysa-background-card-gray-box: var(--color-gray-900);

  --lysa-border-black: var(--color-gray-50);
  --lysa-border-gray: var(--color-gray-700);

  --lysa-story-progress-background: var(--color-blue-gray-400);

  --lysa-input-background: var(--color-gray-700);
  --lysa-ui-legend: var(--lysa-text-secondary);
  --lysa-checkbox-background: var(--color-gray-700);
  --lysa-checkbox-checked-background: var(--color-blue-gray-400);
  --lysa-success-background: var(--color-green-900);
  --lysa-info-background: var(--color-blue-700);
  --lysa-warning-background: var(--color-yellow-900);
  --lysa-error-background: var(--color-red-700);
  --lysa-card-box-shadow: 0 10px 30px 0 rgb(255 255 255 / 10%);

  --lysa-interval-switch-button-background: var(--color-gray-700);

  --lysa-forecast-tooltip-arrow-left-border-color: rgba(255, 255, 255, 0)
    rgb(0, 0, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  --lysa-forecast-tooltip-arrow-right-border-color: rgba(255, 255, 255, 0)
    rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(0, 0, 0);
  --lysa-forecast-tooltip-arrow-border-left-border-color: rgba(255, 255, 255, 0)
    #333 rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  --lysa-forecast-tooltip-arrow-border-right-border-color: rgba(
      255,
      255,
      255,
      0
    )
    rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #333;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  color: var(--lysa-text-primary);
  background-color: var(--lysa-background-screen);
}

// Override ui-2. Should move to there when we've fully committed to dark mode
h1,
h2,
h3,
h4,
h5 {
  color: var(--lysa-text-primary);
}

.lysa-ui-checkbox-group legend,
.lysa-ui-radio legend {
  color: var(--lysa-ui-legend);
}

.lysa-ui-checkbox input[type="checkbox"] + label {
  background-color: var(--lysa-checkbox-background);
}

.lysa-ui-checkbox input[type="checkbox"]:checked + label {
  background-color: var(--lysa-checkbox-checked-background);
}

.lysa-ui-snackbar.lysa-snackbar-success {
  background-color: var(--lysa-success-background);
}

.lysa-ui-snackbar.lysa-snackbar-info {
  background-color: var(--lysa-info-background);
}

.lysa-ui-snackbar.lysa-snackbar-warning {
  background-color: var(--lysa-warning-background);
}

.lysa-ui-snackbar.lysa-snackbar-error {
  background-color: var(--lysa-error-background);
}

.lysa-ui-status-label {
  &.lysa-ui-status-label-success {
    background-color: var(--lysa-success-background);
  }

  &.lysa-ui-status-label-warning {
    background-color: var(--lysa-warning-background);
  }

  &.lysa-ui-status-label-error {
    background-color: var(--lysa-error-background);
  }

  &.lysa-ui-status-label-info {
    background-color: var(--lysa-info-background);
  }
}

.lysa-ui-input,
.lysa-ui-select {
  position: relative;

  input,
  .input-container {
    background-color: var(--lysa-input-background);

    &:focus {
      background-color: var(--lysa-input-background);
    }
  }
}

.card {
  box-shadow: var(--lysa-card-box-shadow);
}

.forecast-tooltip {
  background-color: var(--lysa-background-screen);
}

.react-forecast {
  .on-left {
    .forecast-tooltip-arrow {
      border-color: var(--lysa-forecast-tooltip-arrow-left-border-color);
    }

    .forecast-tooltip-arrow-border {
      border-color: var(--lysa-forecast-tooltip-arrow-border-left-border-color);
    }
  }

  .on-right {
    .forecast-tooltip-arrow {
      border-color: var(--lysa-forecast-tooltip-arrow-right-border-color);
    }

    .forecast-tooltip-arrow-border {
      border-color: var(
        --lysa-forecast-tooltip-arrow-border-right-border-color
      );
    }
  }
}

body {
  margin: 0;
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0;
  }
}

textarea {
  resize: vertical;
}

table {
  border-collapse: collapse;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.lysa-web-app {
  min-height: 100%;
  padding-top: 1px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.relative {
  position: relative;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

p {
  margin: 1.4rem 0;

  &:first-child {
    margin: 0 0 1.4rem 0;
  }
}

.as-link,
a {
  color: var(--lysa-button-primary-enabled);
  transition: color 300ms ease;
  text-decoration: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: inherit;

  &:visited {
    color: var(--lysa-button-primary-enabled);
  }

  &:active,
  &:hover,
  &:focus {
    color: var(--lysa-button-primary-enabled-hover);
  }
}

.compressed-snackbar {
  .lysa-ui-snackbar {
    flex-direction: column;
    padding: 16px;
    margin: 0;

    @include media.landscape {
      padding: 25px;
      flex-direction: row;
    }

    & > div:first-child {
      @include media.landscape {
        flex: 0 0 auto;
      }
    }

    & > div:last-child {
      margin: 10px 0 0 0;
      width: 100%;

      @include media.landscape {
        flex: 0 0 auto;
        margin: 0 0 0 20px;
      }
    }
  }
}

.lysa-web-app {
  &.hide-nav {
    .floating-burger-menu {
      display: none;
    }
  }

  &.see-no-evil {
    .see-no-evil-blur {
      filter: blur(0.5em);
    }
    .see-no-evil-hide {
      display: none;
    }
  }
}

.transparent-button {
  border: 0;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &.as-link {
    display: inline;
  }
}
