@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.withdrawal-item-partial-name {
  line-height: 1.8;
}

.withdrawal-item {
  position: relative;

  .withdrawal-item-name {
    display: flex;

    .withdrawal-item-name--bold {
      font-weight: bold;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
    }
  }

  .account-name {
    line-height: 1.8;
  }

  .amount-tag {
    width: 100%;
    position: absolute;
    text-align: center;
    z-index: 2;
    top: 30px;

    & > div {
      display: inline-block;
      padding: 4px 6px;
      line-height: 1;
      color: white;
      background-color: #333;
      border-radius: 3px;
    }
  }

  .arrow-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0 2px 0;

    span {
      opacity: 0.7;
      flex: 0 0 auto;
      padding: 0 0 0 16px;

      &:first-child {
        padding: 0 16px 0 0;
      }
    }
  }

  .transaction-arrow {
    height: 20px;
    position: relative;
    padding: 9px 10px 9px 5px;
    flex: 1 0 auto;
    align-items: center;

    .arrow-start {
      height: 8px;
      width: 8px;
      border-radius: 4px;
      position: absolute;
      top: 6px;
      left: 0;
      background-color: variables.$color-darkgray;
    }

    .arrow-body {
      height: 2px;
      background-color: variables.$color-darkgray;
    }

    .arrow-top {
      position: absolute;
      top: 0;
      right: 0;
      fill: variables.$color-darkgray;
    }
  }

  .withdrawal-item-to {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div:first-child {
      opacity: 0.7;
      font-size: 12px;
      font-style: italic;
    }

    .withdrawal-item-to__account-and-logo {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
