.transparent-button-component {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: inherit;
  line-height: 24px;
  margin: 0;
  padding: 0;
  width: auto;

  &:focus {
    outline: 0;
  }
}
