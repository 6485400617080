.close-savings-account {
  .lysa-ui-snackbar {
    padding: 16px 24px 16px 24px;
    margin-bottom: 0px;

    .close-savings-account__snackbar {
      text-align: left;
    }
  }
}
