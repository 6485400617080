@use "~@lysaab/ui-2/components/styling/media";

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
}

$height: 60px;

.thread {
  position: relative;

  .unstyled-button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    text-align: inherit;
    border-radius: 0;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
    text-align: center;
    outline: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }

  .thread-button {
    width: 100%;
    height: $height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    padding: 0 14px;
    position: relative;
    white-space: nowrap;

    & > span:last-child {
      opacity: 0.7;
      font-size: 13px;
    }

    &.is-read {
      font-weight: normal;
    }

    .all-but-date {
      display: flex;
      align-items: center;
      min-width: 0;
    }

    .signature {
      margin-right: 10px;
    }

    .preview {
      @include ellipsis();
      padding-right: 1em;
    }

    .short-message {
      opacity: 0.7;
    }
  }

  .message {
    position: relative;

    .signature {
      position: absolute;
      top: 10px;
    }
  }

  .from-customer {
    margin: 0 30px 10px 50px;

    @include media.tablet {
      margin: 0 30px 20px 70px;
    }

    @include media.desktop-plus {
      margin: 0 36px 20px 100px;
    }

    .message-body {
      background-color: var(--lysa-background-card-ligthblue-box);
    }

    .signature {
      right: -30px;

      @include media.desktop-plus {
        right: -35px;
      }
    }

    .arrow {
      right: -10px;
      border-top: 10px solid var(--lysa-background-card-ligthblue-box);
    }
  }

  .from-admin {
    margin: 0 50px 10px 30px;

    @include media.tablet {
      margin: 0 70px 20px 30px;
    }

    @include media.desktop-plus {
      margin: 0 100px 20px 36px;
    }

    .message-body {
      background-color: var(--lysa-background-card-gray-box);
    }

    .signature {
      left: -30px;

      @include media.desktop-plus {
        left: -35px;
      }
    }

    .arrow {
      left: -10px;
      border-top: 10px solid var(--lysa-background-card-gray-box);
    }
  }

  .message-body {
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    font-size: 12px;
    white-space: pre-wrap;

    @include media.tablet {
      font-size: 14px;
    }
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 0;
  }

  .message-date {
    text-align: right;
    font-weight: 500;
    font-size: 0.9em;
    margin: 6px 0 0 0;
  }

  .message-file {
    text-align: right;
    font-family: monospace;
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
    font-size: 0.9em;
  }
}
