@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.modal-list {
  padding-bottom: 40px;
}

.account-page-positions-data,
.account-page-positions-example,
.modal-list {
  h4 {
    margin-top: 70px;
    margin-bottom: 30px;
  }

  dl {
    margin: 16px 0 50px 0;
  }

  dd,
  dt {
    margin: 0;
    font-size: 13px;
    line-height: 1.5;

    @include media.tablet {
      font-size: 14px;
    }
  }

  dt {
    margin: 12px 0 0 0;
    display: flex;
    justify-content: left;
    > b {
      white-space: normal;
    }

    &.summary-cell {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      & > b {
        display: block;
        flex: 0 1 auto;
        min-width: 0;
        overflow: hidden;
        text-overflow: clip;
        text-overflow: ellipsis;
      }

      & > .amount {
        flex: 1 0 auto;
        text-align: right;
      }

      @include media.tablet {
        font-size: 15px;
      }
    }
  }

  dd {
    padding: 0 0 8px 0;
    border-bottom: 1px solid variables.$color-secondary-enabled;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &.summary-cell {
      color: #757677;
      border-bottom: 0;

      + dt {
        margin: 50px 0 0 0;

        @include media.tablet {
          margin: 70px 0 0 0;
        }
      }
    }
  }
}
