.transfer-pension-story-withdrawal-plan {
  .header {
    margin-bottom: 3rem;
  }

  .glide-path {
    margin: 1rem 0 1rem 0;
  }

  .heading {
    margin-top: 3rem;
    margin-bottom: 0.6rem;
  }

  .withdrawal-heading {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    .withdrawal-information-button {
      border: none;
      min-height: 0;
      height: 18px;
      width: 18px;
    }
  }

  .selectable-card {
    /* Override default SelectableCard style  */
    box-shadow: none !important;
    margin-bottom: 1rem;
    width: 100%;
    background-color: var(--color-gray-50);

    .selectable-card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .start {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .end {
      display: flex;
      align-items: center;
      gap: 0.6rem;

      p {
        margin: 0;
      }
    }
  }

  .repayment {
    margin-bottom: 3rem;
  }

  .save-button {
    margin-top: 3rem;
  }
}
