
.events-switch {

  transition: opacity 300ms ease;
  opacity: 1;

  .flex {
    align-items: center;
  }

  .lysa-ui-toggle {
    margin: 0 0 0 20px;
  }
}

.graph-card.show-overlay {
  .events-switch {
    opacity: 0.4;
  }
}