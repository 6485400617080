.transfer-pension-digital-signing {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .list-item {
    --blue: var(--lysa-brand-blue);
    --radius: 1rem;
    --rotate: 0deg;
    position: relative;
    padding: 1.8rem 2.2rem;
    background-color: var(--color-gray-50);
    border-radius: 1rem;
    overflow: hidden;

    .content {
      position: relative;
      display: grid;
      align-items: center;
    }

    .glow {
      position: absolute;
      inset: 0;
      &:before {
        content: "";
        background: conic-gradient(
          from var(--rotate),
          transparent 0%,
          var(--blue) 10%,
          transparent 20%
        );
        position: absolute;
        inset: 0;
        animation: spin 3s linear infinite;
      }
      &:after {
        content: "";
        background-color: var(--color-gray-50);
        position: absolute;
        inset: 2px;
        border-radius: calc(var(--radius) - 2px);
      }
    }
  }

  .list-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    .list-item-header-left {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      .list-item-header-left__pension-logo {
        flex-shrink: 0;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .information-divider {
    height: 1px;
    background-color: black;
    opacity: 0.15;
    border: none;
    margin: 1.6rem 0 1.2rem 0;
  }

  .actions {
    dl {
      display: flex;
      justify-content: space-between;

      dd {
        font-weight: bold;
        text-align: end;
      }
    }
  }

  .actions-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .error {
    margin-top: 2rem;
  }

  .invisible {
    font-size: 12px;
    padding: 4px 0;
    visibility: hidden;
  }

  .next {
    margin-top: 2rem;
  }
}
