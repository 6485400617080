.chip {
  padding: 0.2rem 1rem;
  border-radius: 16px;
  background-color: transparent;
  border: 1px solid var(--lysa-button-secondary-border);
  font-weight: bold;
}

.selected {
  background-color: black;
  color: white;
}
