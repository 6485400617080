@use "sass:math";
@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

$grid-columns: 12;
$grid-breakpoints: (
  xs: 0,
  sm: variables.$landscape,
  md: variables.$tablet,
  lg: variables.$desktop,
  xl: variables.$desktop-plus,
);

/* Gaps between columns. Also used for negative margin on row to align with page margins */
$column-gaps: (
  xs: 10px,
  sm: 10px,
  md: 20px,
  lg: 20px,
  xl: 40px,
);

@mixin make-row-cols($count) {
  > * {
    flex: 0 0 auto;
    width: 100% / $count;
  }
}

/* Constructs the grid classes: column classes .col-md-4 */
@mixin make-grid() {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: if($breakpoint == "xs", "", "-#{$breakpoint}");

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      /* Creates the column classes, e.g .col-md-4 */
      @for $i from 1 through $grid-columns {
        .lysa-ui-row > .col#{$infix}-#{$i} {
          flex-grow: 0;
          width: percentage(math.div($i, $grid-columns));
          float: left;
        }
      }
    }
  }
}

@mixin media-breakpoint-up($name, $breakpoints) {
  @if $name == "xs" {
    @content;
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

@mixin make-col-paddings() {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $column-gap: map-get(
      $map: $column-gaps,
      $key: $breakpoint,
    );
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      padding-left: calc(#{$column-gap} / 2);
      padding-right: calc(#{$column-gap} / 2);
    }
  }
}

@mixin make-row-margins() {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $column-gap: map-get(
      $map: $column-gaps,
      $key: $breakpoint,
    );
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      margin-left: calc(-#{$column-gap} / 2);
      margin-right: calc(-#{$column-gap} / 2);
    }
  }
}

@mixin create-classes-from-value-array($property, $valueArray) {
  @each $modifier, $value in $valueArray {
    .#{$property}-#{$modifier} {
      #{$property}: #{$value};
    }
  }
}
