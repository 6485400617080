.cancel-pending-transaction {
  $height: 48px;

  position: relative;
  z-index: 1;
  height: $height;
  margin-top: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  &--error {
    height: auto;
    min-height: $height;
  }
}

.cancel-pending-transaction__confirm {
  position: absolute;
  text-align: center;
  z-index: 2;
  background-color: var(--lysa-background-screen);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 16px;

  .lysa-ui-button.block {
    margin: 0;
    max-width: 400px;
  }
}

.modal .cancel-pending-transaction__confirm {
  background-color: var(--lysa-background-canvas);
}
