@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.confirm-page {
  .card {
    @include media.tablet {
      font-size: 15px;
    }
  }

  h4 svg {
    margin: 0 8px 0 0;
    position: relative;
    top: 3px;
  }

  dl {
    margin: 0;
    padding: 0;

    & + div {
      opacity: 0.8;
      margin: 0 0 4px 0;
    }
  }

  dt,
  dd {
    vertical-align: top;
    width: 50%;
    display: inline-block;
    margin: 0 0 6px 0;
  }

  dt {
    opacity: 0.8;
  }

  .allocation-bar-value {
    font-weight: 600;
  }

  .allocation-bar-name {
    margin: 0 4px;
    opacity: 0.8;
    font-style: italic;
  }

  dd {
    font-weight: 500;
    text-align: right;
  }

  .button-primary {
    margin: 10px 0 0 0;
  }

  .as-link {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    color: variables.$color-primary-enabled;
    text-decoration: underline;
    display: block;
  }
}
