@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.performance-graph-legend {
  padding: 0 16px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  opacity: 0.7;

  @include media.tablet {
    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 0 24px;
  }

  @include media.desktop {
    width: 450px;
    flex: 0 0 450px;
    padding: 0;
  }

  .graph-indicator {
    display: inline-block;
    margin: 0 6px 0 0;
    width: 8px;
    height: 8px;
  }

  li {
    display: flex;
    justify-content: space-between;
    height: 18px;
    margin: 0;

    @include media.tablet {
      height: 22px;
    }

    & > div {
      flex: 1 1 auto;
      min-width: 0;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > div:last-child {
      text-align: right;
      flex: 1 0 50px;
    }
  }

  .performance-graph-legend-lysa {
    .graph-indicator {
      background-color: var(--lysa-indicator-graph-line);
    }
  }

  .performance-graph-legend-internal,
  .performance-graph-legend-deposit,
  .performance-graph-legend-withdrawal {
    transition: opacity 300ms ease;
    opacity: 0;

    .graph-legend-withdrawals-total,
    .graph-legend-deposits-total {
      flex: 0 0 100px;
      text-align: right;
    }
  }

  .performance-graph-legend-deposit {
    margin: 4px 0 0 0;

    .graph-indicator {
      background-color: var(--lysa-indicator-deposit);
      border-radius: 4px;
    }
  }

  .performance-graph-legend-withdrawal {
    .graph-indicator {
      background-color: var(--lysa-indicator-withdrawal);
      border-radius: 4px;
    }
  }

  .performance-graph-legend-internal {
    .graph-indicator {
      background-color: var(--lysa-indicator-internal);
      border-radius: 4px;
    }
  }

  &.show-events-legend {
    .performance-graph-legend-internal,
    .performance-graph-legend-deposit,
    .performance-graph-legend-withdrawal {
      opacity: 1;
    }
  }

  .performance-graph-legend-interval {
    align-items: center;

    span {
      flex: 1 1 auto;
      height: 1px;
      display: inline-block;
      background-color: variables.$color-black;
    }

    .graph-legend-interval {
      flex: 0 0 auto;
      background-color: var(--lysa-background-screen);
      padding: 0 12px;
      height: auto;
      text-align: center;
      font-style: italic;
    }
  }
}
