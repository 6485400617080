@use "~@lysaab/ui-2/components/styling/variables";

.denmark-tax-details {
  .tax-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;
    margin-bottom: 20px;

    .tax-entry-wrapper {
      background: rgba(variables.$color-lightgray, 0.75);
      padding: 12px;
      border-radius: 8px;
      flex: 1 1 calc(50% - 8px);
    }
  }

  h4 {
    margin-bottom: 8px;
  }

  h3 {
    margin-bottom: 8px;
  }

  .tax-header-left {
    max-width: 400px;
    width: 100%;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
  }
  dt {
    width: 60%;
    font-weight: 600;
  }
  dd {
    margin-left: auto;
    width: 40%;
    text-align: right;
  }

  .tax-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .tax-print-button {
    margin: 12px 0 12px 12px;
  }

  .reverse-wrap {
    flex-wrap: wrap-reverse;
  }
}
