.account-page-pending-internal-transfers {
  .pending-internal-transfers-entry {
    display: flex;
    justify-content: space-between;
  }

  .account-page-pending-internal-transfers-list {
    position: relative;
  }

  .additional-pending-internal-transfers-indicator {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 1;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  &.has-additional-pending-internal-transfers {
    .additional-pending-internal-transfers-indicator {
      display: block;
    }
  }
}
