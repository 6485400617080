.withdrawal-page-wrapper {
  .withdrawal-page-boundary {
    margin: 0 auto;
    max-width: 600px;

    .withdrawal-page {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .card {
        margin: 0;
      }
    }
  }
}
