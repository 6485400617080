.move-card-read-more-button {
  margin: 0;
  padding: 0.2rem 0 !important;
  min-height: auto;
  line-height: normal;

  .typography--label {
    line-height: 16px;
  }
}
