.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px; /* Adjust size as needed */
  height: 18px;
}

.loading-arc {
  width: 100%;
  height: 100%;
  border: 3px solid var(--lysa-border-gray); /* Border width adjusts thickness */
  border-top-color: var(--lysa-brand-blue);
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
