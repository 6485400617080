@use "~@lysaab/ui-2/components/styling/variables";

.edit-allocation-positions-page {
  margin-bottom: 24px;

  .disclaimer {
    clear: both;
    font-size: 12px;
    line-height: 20px;
    padding-top: 10px;
  }
}
