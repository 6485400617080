:root {
  --lysa-cmd-k-box-shadow: 0 6px 20px rgba(0 0 0 / 20%);
}

[data-theme="dark"] {
  --lysa-cmd-k-box-shadow: 0 6px 20px rgba(255 255 255 / 20%);
}

.cmd-k {
  /** Snackbars have z-index 15, and this one should display ontop of those */
  z-index: 16;

  .cmd-k-animator {
    max-width: 600px;
    width: 100%;
    background: var(--lysa-background-screen);
    color: var(--lysa-text-primary);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: var(--lysa-cmd-k-box-shadow);
  }

  .cmd-k-search {
    padding: 12px 16px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    background: var(--lysa-background-screen);
    color: var(--lysa-text-secondary);
  }
}
