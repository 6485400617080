@use "~@lysaab/ui-2/components/styling/variables";

.edit-allocation-fees-page {
  margin-bottom: 24px;

  .header {
    display: flex;

    h4 {
      flex: 1;
    }
  }

  h4.horizon-slider-title,
  h4.expected-yield-slider-title {
    display: flex;
    justify-content: space-between;
  }

  .controls {
    margin-bottom: 24px;
  }

  .summary .header {
    margin-bottom: 0;
  }

  dl .total {
    font-weight: bold;
    border: 0;
    padding-bottom: 24px;
  }

  dt {
    float: left;
    width: 70%;
    margin: 4px 0;
    padding: 4px 0;
    border-bottom: 1px solid variables.$color-lightgray;
  }

  dd {
    float: right;
    width: 30%;
    text-align: right;
    margin: 4px 0;
    padding: 4px 0;
    border-bottom: 1px solid variables.$color-lightgray;
  }

  dd::after {
    content: " ";
    display: block;
    clear: left;
  }

  .fee-disclaimer {
    clear: both;
    font-size: 12px;
    line-height: 20px;
    padding-top: 10px;
  }

  .cost-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
