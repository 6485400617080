@use "sass:math";
@use "~@lysaab/ui-2/components/styling/variables";

:root {
  --lysa-return-information-modal-example-background: #{variables.$color-lightblue};
}

[data-theme="dark"] {
  --lysa-return-information-modal-example-background: var(--color-blue-700);
}

.return-information-modal {
  .example-block {
    background-color: var(--lysa-return-information-modal-example-background);
    padding: 12px;
    border-radius: 4px;
    margin-top: 0;

    > p:last-child {
      margin-bottom: 0;
    }
  }
}
