@use "~@lysaab/ui-2/components/styling/media";

.overlay-legend {
  position: absolute;
  // min-height: 60px;
  width: calc(
    100% + 36px
  ); // 36px corresponds to horizontal margin of "performance-graph"
  background-color: var(--lysa-background-screen);
  bottom: 100%;
  left: -8px; // corresponds to left margin of "performance-graph"
  padding: 0 16px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  z-index: 10;
  pointer-events: none;

  @include media.tablet {
    max-width: 450px;
    font-size: 1.4rem;
    line-height: 2.2rem;
    width: 100%;
    padding: 0;
    left: 0;
    margin: 0 0 0 -225px;
  }

  .graph-indicator {
    display: inline-block;
    margin: 0 6px 0 0;
    width: 8px;
    height: 8px;
  }

  li {
    opacity: 0.7;
    display: flex;
    justify-content: space-between;
    height: 18px;
    margin: 0;

    @include media.tablet {
      height: 22px;
    }

    & > div {
      min-width: 0;
      min-width: 0;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .overlay-legend-name {
      flex: 1 1 auto;
    }

    .overlay-legend-change {
      text-align: right;
      flex: 0 0 55px;
      font-weight: bold;

      @include media.tablet {
        flex: 0 0 70px;
      }
    }
  }

  .overlay-legend-lysa {
    .graph-indicator {
      background-color: var(--lysa-indicator-graph-line);
    }

    .overlay-legend-worth {
      text-align: right;
      flex: 0 0 105px;
      width: 105px;
    }
  }

  .overlay-legend-interval {
    display: flex;
    align-items: center;

    i {
      flex: 0 0 auto;
      background-color: var(--lysa-background-screen);
      padding: 0 12px;
      text-align: center;
      display: inline-block;
    }

    span {
      flex: 1 1 auto;
      height: 1px;
      display: inline-block;
      background-color: #000000;
    }
  }

  .performance-overlay-legend-deposit {
    margin: 4px 0 0 0;
  }

  .performance-overlay-legend-internal,
  .performance-overlay-legend-withdrawal,
  .performance-overlay-legend-deposit {
    transition: opacity 300ms ease;
    display: flex;
    flex-wrap: nowrap;
    opacity: 0;

    .graph-indicator {
      background-color: var(--lysa-indicator-deposit);
      border-radius: 4px;
    }

    &.is-visible {
      opacity: 0.7;
    }
  }

  .performance-overlay-legend-withdrawal {
    .graph-indicator {
      background-color: var(--lysa-indicator-withdrawal);
      border-radius: 4px;
    }
  }

  .performance-overlay-legend-internal {
    .graph-indicator {
      background-color: var(--lysa-indicator-internal);
      border-radius: 4px;
    }

    .internal-amount,
    .amount {
      display: inline-block;
    }
  }

  .overlay-legend-type {
    flex: 0 0 80px;

    @include media.tablet {
      flex: 0 0 120px;
    }
  }

  .overlay-legend-event-name {
    flex: 1 1 auto;
    min-width: 0;
    padding: 0 12px 0 0;
    font-style: italic;
  }

  .overlay-legend-amount {
    flex: 1 0 80px;
    text-align: right;
    white-space: nowrap;
  }

  .performance-overlay-legend-internal {
    b {
      font-weight: 500;
    }

    .internal-postfix {
      opacity: 0.7;
      text-transform: uppercase;
      font-size: 0.8em;
      margin: 0 0 0 0.3em;
      font-weight: normal;
    }

    .overlay-legend-event-name {
      text-align: center;
    }

    @include media.tablet {
      .overlay-legend-type {
        flex: 0 0 100px;
      }

      .overlay-legend-amount {
        flex: 0 0 120px;
      }
    }
  }
}
