.marketing {
  .infoText {
    padding-bottom: 20px;
  }

  .lysa-ui-radio {
    margin-bottom: 0;
  }

  button {
    margin-top: 28px;
  }
}
