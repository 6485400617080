.retry-wrapper {
  position: relative;

  .retry-error,
  .retry-pending {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .retry-pending {
    .lysa-ui-spinner {
      max-width: 100px;
    }
  }

  .retry {
    pointer-events: all;
  }
}
