.percentage-currency-toggle {
  position: relative;

  input[type="checkbox"] {
    & + label {
      border: 1px solid var(--lysa-text-summary-blue);
      display: flex;
      justify-content: center;
      /* To remove the default hightlight tap color on chrome. */
      -webkit-tap-highlight-color: transparent;

      .toggle-handle {
        margin: 3px;
        will-change: left;
        transition: left 0.1s ease, background-color 0.1s ease;
        background-color: var(--lysa-text-summary-blue);
      }
    }
  }

  .toggle-text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;

    .toggle-text-inner-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
    }

    .toggle-text {
      line-height: 14px;
      z-index: 2;
    }

    .toggle-sm-text {
      font-size: 9px;
    }

    .toggle-md-text {
      font-size: 12px;
    }
  }

  &.toggle-primary {
    .toggle-text {
      &.active {
        color: var(--lysa-text-white);
      }
      color: var(--lysa-text-blue);
    }

    input[type="checkbox"] {
      & + label {
        border: 1px solid var(--lysa-text-summary-blue);
        background-color: var(--lysa-text-blue);

        .toggle-handle {
          background-color: var(--lysa-text-white);
        }
      }

      &:checked + label {
        background-color: var(--lysa-text-blue);
        &:hover {
          background-color: var(--lysa-text-blue);
        }
      }
    }
  }

  &.toggle-secondary {
    .toggle-text {
      &.active {
        color: var(--lysa-border-black);
      }
      color: var(--lysa-text-white);
    }

    input[type="checkbox"] {
      & + label {
        border: 1px solid var(--lysa-text-secondary);
        background-color: var(--lysa-text-white);

        .toggle-handle {
          background-color: var(--lysa-border-black);
        }
      }

      &:checked + label {
        background-color: var(--lysa-text-white);
        &:hover {
          background-color: var(--lysa-text-white);
        }
      }
    }
  }
}
