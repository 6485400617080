.StepIndicator {
  --icon-size: 28px;
  --icon-half-size: calc(var(--icon-size) / 2);
  --step-space: 20px;

  width: 100%;
  margin-bottom: 32px;
  text-align: left;

  &__step {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;

    & + & {
      margin-top: var(--step-space);
    }

    --icon-background-color: transparent;
    --icon-border: 2px solid var(--lysa-button-disabled-color);
    --animation-connector-offset: 0px;
    --top-connector-color: var(--lysa-button-disabled-color);
    --bottom-connector-color: var(--lysa-button-disabled-color);

    &--done {
      --icon-background-color: var(--lysa-button-primary-enabled);
      --icon-border: none;
      --top-connector-color: var(--lysa-button-primary-enabled);
      --bottom-connector-color: var(--lysa-button-primary-enabled);
    }

    &--active {
      --icon-border: 2px solid var(--lysa-button-primary-enabled);
      --icon-animation: pulse 1.4s infinite;
      --animation-connector-offset: 1px;
      --top-connector-color: var(--lysa-button-primary-enabled);
      --bottom-connector-color: var(--lysa-button-disabled-color);
    }

    // Connection lines to previous and next steps.
    &:not(:first-child)::before,
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      width: 2px;
      left: calc(var(--icon-half-size) - 1px);
      z-index: 0;
    }

    // Connection line to previous step.
    &:not(:first-child)::before {
      top: 0;
      height: calc(
        50% - var(--icon-half-size) + var(--animation-connector-offset)
      );
      background-color: var(--top-connector-color);
    }

    // Connection line to next step.
    &:not(:last-child)::after {
      top: calc(
        50% + var(--icon-half-size) - var(--animation-connector-offset)
      );
      height: calc(
        50% - var(--icon-half-size) + var(--animation-connector-offset) +
          var(--step-space)
      );
      background-color: var(--bottom-connector-color);
    }
  }

  &__icon {
    height: var(--icon-size);
    width: var(--icon-size);
    min-width: var(--icon-size);
    display: grid;
    place-items: center;
    border-radius: 50%;
    background-color: var(--icon-background-color);
    border: var(--icon-border);
    animation: var(--icon-animation);
    z-index: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--lysa-button-primary-enabled);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(228, 80, 32, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(228, 80, 32, 0);
  }
}
