.account-share-card {
  margin-bottom: 20px;

  .date {
    font-size: 0.9em;
    opacity: 0.65;
    display: block;
    line-height: 1.2em;
  }

  .delete-button {
    width: auto;
    display: block;
    flex-shrink: 0;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
      min-width: 0;

      > * {
        margin-right: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }
  }

  .info {
    margin-top: 10px;
  }
}
