@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.pending-deposit-card {
  .card {
    padding: 0;
    border: 0;
  }

  .title {
    .badge {
      margin: 0;
      margin-left: 10px;
    }
  }

  .expand-button {
    padding: 14px 0;
    width: 100%;
    color: variables.$color-blue;
    transition: color 0.15s;
    font-weight: 500;

    &:hover {
      color: variables.$color-primary-hover;
    }
  }

  .rah-static {
    position: relative;
  }

  .rah-static--height-specific:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 1;
    background: linear-gradient(rgba(247, 247, 247, 0), rgba(247, 247, 247, 1));
  }

  .pending-deposit-table-container {
    position: relative;
    padding: 15px 22px;

    table {
      width: 100%;
    }

    td:first-child {
      margin-top: 0;
    }

    th.pending-col-text {
      text-align: left;
    }

    .pending-col-number {
      text-align: right;
    }

    .abort-container {
      height: 26px; //Same height as text
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0;
      margin: 0;
      button:hover {
        cursor: pointer;
      }

      svg {
        margin-right: 0.5rem;
      }

      &:before {
        margin: 0;
      }
    }
    // @media (max-width: variables.$tablet) {
    .tablet-td-hr {
      border-bottom: 1px solid lightgray;
    }
    .abort-container {
      justify-content: center;
    }
    tr {
      border-bottom: 20px solid transparent;
    }
    tr:last-child {
      border-bottom: none;
    }
    // }
  }
}
