@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";

.pending-direct-debit-transactions {
  .card {
    padding: 0;
    border: 0;
  }

  .rah-static {
    position: relative;
  }

  .rah-static--height-specific:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(rgba(247, 247, 247, 0), rgba(247, 247, 247, 1));
  }

  .pending-direct-debit-transactions__content {
    padding: 15px 14px;
  }

  .pending-direct-debit-transactions__bottom-button {
    padding: 14px 0;
    width: 100%;
    color: variables.$color-blue;
    transition: color 0.15s;
    font-weight: 500;

    &:hover {
      color: variables.$color-primary-hover;
    }
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 22px;
    line-height: 1.4;

    .badge {
      margin: 0 0 0 1rem;
    }
  }

  .pending-direct-debit-transactions__transaction {
    border-bottom: 1px solid lightgray;
    margin-bottom: 2rem;

    &:last-child {
      border: none;
    }
  }

  .pending-direct-debit-transactions__detail {
    font-weight: bold;
    font-size: 16px;
    padding: 4px 0 2rem;
  }
}
