@use "~@lysaab/ui-2/components/styling/media";

.confirm-action-overlay {
  position: relative;

  .content {
    &.fade-content {
      opacity: 0.05;
    }
  }

  .remove-error,
  .remove-confirm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: opacity 300ms ease;
    z-index: 3;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    .lysa-ui-button {
      margin: 0 0 10px 0;

      @include media.tablet {
        margin: 0 5px;
      }
    }
  }
}
