.transfer-pension-not-movable-pensions-card {
  /* Override default SelectableCard style  */
  box-shadow: none !important;
  margin-bottom: 2rem;
  width: 100%;
  background-color: var(--color-gray-50);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .start {
    display: flex;
    align-items: center;
    gap: 16px;

    .counter-circle {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--lysa-background-card);
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: var(--lysa-text-primary);
    }

    .counter-value {
      display: inline-block;
    }

    .label {
      color: var(--lysa-text-secondary);
    }
  }

  .end {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
